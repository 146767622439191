<template>
</template>

<script>

import $ from "jquery"
import darsan from "darsan"
import {animate} from "common"

export default {
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    size: {
      type: Number,
      default: 6,
    },
    modelValue: {
       type: [Number, String, Array, Boolean],
    },
    labelSize: {
      type: Number,
      default: 3,
    },
    validate: Function,
    apiDomain: {
      type: String,
      default: "",
    },
    apiTopic: {
      type: String,
    },
    apiPath: {
      type: String,
    },
    save: {
      type: [Function, Promise],
    },
    info: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    // Дополнительные параметры для идентификации контрола
    extra: {
      type: [String, Number, Object],
    },
  },
  
  emits: [ 'update:modelValue' ],
  
  data()
  {
    return { 
       error: null,
       previous: null,
       has_undo: false,
       already_saved: false,
       localValue: null,
    }
  },
  
  methods: {
    newValue(ev)
    {
      this.setNewValue($(ev.target).val())
    },

    setNewValue(val)
    {
      if (this.already_saved) return

      if (val==this.previous) return

      if (this.validate)
      {
        const err = this.validate(val)
      
        if (err)
        {
          this.error = err
          return
        }
      }
      
      this._saveValue(val, this.previous)
      this.$el.querySelector(this.element).blur()
    },
    
    savePrevious()
    {
      this.setPrevious($(this.$el.querySelector(this.element)).val())
    },

    setPrevious(val)
    {
      this.previous = val
      this.error = null
      this.already_saved = false
    },
    
    _saveValue(val, oldVal, has_undo=true)
    {
      const saveFunc = this.save || (val => darsan.patch(this.apiDomain, this.apiTopic, this.apiPath, {[this.name]: val}))

      const newVal = this.undecorateValue(val)
      saveFunc(newVal, oldVal, this.extra).then(() => 
      {
        animate(this.animatedElement(), "animate_success")
        this.has_undo = has_undo
        this.error = null
        this.already_saved = true
        this.$emit("update:modelValue", newVal)
      })
      .fail(xhr => 
      {
        this.error = darsan.errorText(xhr, " ", true)
        animate(this.animatedElement(), "animate_fail")
        console.log(xhr)
      })
    },
    
    animatedElement()
    {
      return this.$el.querySelector(this.element)
    },
    
    undo()
    {
      this._saveValue(this.previous, this.modelValue, false)
      this.previous = null
    },
    
    decorateValue(val)
    {
      return val
    },
    
    undecorateValue(val)
    {
      return val
    },
    
  },
  
  watch: {
    modelValue: {
      immediate: true,
      handler(val)
      {
        this.localValue = this.decorateValue(this.modelValue)
      }
    },
  },
  
}
</script>

<style scoped>
.form-control-feedback
{
  right: -10px !important;
}
</style>
