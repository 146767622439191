<template>
    <td v-if="row instanceof Object && !Array.isArray(row)">
    <table>
      <tr v-for="k in keysOf(row)" >
        <td class="name">{{ humanName(k) }}</td>
        <SingleRow :attr=attr :row=row[k] />
      </tr>
    </table>
    </td>
    <td v-else>{{row}}</td>
</template>  

<script>
import _ from "underscore"

export default {

  name: "SingleRow",
  
  props: {
    row: null,
    attr: {
      type: Function,
      default: n => { return {} },
    },
  },
  
  methods: {
    keysOf(rec)
    {
      return _.sortBy(Object.keys(rec), name => this.humanName(name))
    },
    
    humanName(name)
    {
      return this.attr(name)?.human || name
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
        
tbody tr:nth-child(even) {
  backdrop-filter: brightness(90%);
}

td.name {
  width: 10%;
  border-right: 1px solid lightgray;
  padding-left: 0;
  padding-right: 1em;
  font-weight: bold;
}

td {
  padding-left: 1em;
}
</style>