<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px" >
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize" >{{label}}</label>
      <div :class="'col-sm-'+size">
          <select class="form-control" :multiple=multiple :name=name :id=uniq >
            <option v-for="o in options" :key=o.value :value=o.value >{{o.text}}</option>
          </select>
      <span class="help-block">{{error}}</span>
    </div>
    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>
  </div>
</template>

<script>

import _ from "underscore"
import $ from "jquery"

export default {
  name: "Select2",
 
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    size: {
      type: Number,
      default: 6,
    },
    modelValue: {
      type: [Number, String, Array],
    },
    labelSize: {
      type: Number,
      default: 3,
    },
     error: {
      type: String,
    },
    info: {
      type: String,
    },
    multiple: Boolean,
  },
  
  emits: [ 'update:modelValue' ],

  data() 
  {
    return {
      uniq: null,
    }
  },
  
  created()
  {
    this.uniq = _.uniqueId("select")
  },
  
  mounted()
  {
    const el = $(this.$el.querySelector("select"))
    //el.select2({ tags: true })
    el.select2()
    el.on("change", ev => this.$emit("update:modelValue", $(ev.target).val()))
    el.select2("val", this.modelValue)
  },
    
 
  watch: {
    modelValue(val) { $(this.$el.querySelector("select")).select2("val", this.modelValue) }
  },
 
}
</script>
