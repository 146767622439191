<template>
  <div class="main">
    <div v-for="name in Object.keys(sections).sort()">
      <h5 v-if="sections[name].length>0">{{name}}</h5>
      <template v-for="l in sections[name]">
        <template v-if="l.type=='list'">
          <Flipper title="..." :shy=true v-if="l.text.length>3">
             <div v-for="item in l.text" class="item" v-html="item"></div>
          </Flipper>
          <template v-else>
            <div v-for="item in l.text" class="item" v-html="item"></div>
          </template>
        </template>
        <div v-else :class="l.type" v-html="l.text"></div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from "underscore"
import Flipper from "common/visual/Flipper.vue"

export default {
  name: "Console",
  components: {Flipper},
   
  data()
  {
    return {
      sections: {},
    }
  },
  
  methods: {
    print(name, type, text)
    {
      if (!(name in this.sections))
      {
        this.sections[name] = []
      }
      
      this.sections[name].push({type, text})
    },
  },
  
  printList(name, list)
  {
    
  },
  
  computed: {
    sortedSections()
    {
      return _.sortBy(this.sections, "name")
    }
  },
}
</script>

<style scoped>
.main {
  background: white;
}

.error {
  color: red;
}

.info {
  color: black;
}

.header {
  font-weight: bold;
}

.item {
  display: list-item;
  margin-left: 2em;
}

h5 {
  background: rgb(230,230,255);
  margin: 1em 0;
  padding: 0.5em;
}
</style>
