define([
  'jquery',
  'underscore',
  'darsan',
], function($, _, darsan)
{
  return {

    load: function(login)
    {
      var me = this;

      return darsan.promiseDarsanToken2().then( rec =>
      {
        const headers = {
          "Authorization": "Darsan2 " + rec.token,
        };
        
        if (rec.pretend)
        {
          headers["X-Darsan-Pretend"] = rec.pretend;
        }
                              
        return $.ajax(API+"/allowed-modules", {headers})
        .done( list =>
        {
          me.modules = list;
        });
      })
    },

    allowed: function(name)
    {
      name = name.replace(/^\//,"");
//      console.log(name, _.indexOf(this.modules, name) !== -1)
      return _.indexOf(this.modules, name) !== -1;
    },

    filterLayout: function(layout)
    {
      var me = this;
      return _.filter(layout,(el) =>
      {
        if (!("modPath" in el)) return true
        return me.allowed(el.modPath)
      });
    },

  };
});
