<template>
  <input class="form-control" :name=name :value=modelValue />
</template>

<script>
import * as select_adv from '_lib/select_adv/select_adv'
import _ from "underscore"
import $ from "jquery"

export default {
  name: "SelectAdv",

  props: {
    name: {
      type: String,
    },
    modelValue: {
       type: String,
       default: "",
    },
    options: {
      type: Array,
    },
    
    init: Function,
    search: Function,
    advSearch: Function,
    limit: {
      type: Number,
      default: 15,
    },
    leftAddonTemplate: {
      type: String
    }
  },
  
  emits: [ 'update:modelValue' ],
  
  data()
  {
    return {
      select_adv: null,
    }
  },
  
  mounted()
  {
    const me = this

    this.select_adv = select_adv($(this.$el), {
      limit: me.limit,

      oninit: function(opt, func)
      {
        if (me.init)
        {
	        func(me.init(opt))
       	}
       	else
       	{
	        func(opt)
      	}
      },

      onsearch: function(what, func)
      {
        me.search(what).then(data => func(data))
      },
/*
       onadvsearch: function(opt, func)
       {
         me.advSearch(opt).then(data => func(data))
       },
*/
       onselect: function(opt, func)
       {
          func(opt)
          me.$emit("update:modelValue", opt.id)
        },

        leftAddonTemplate: me.leftAddonTemplate,
        
        onfocus(ev) { me.$emit("focus", ev) },
      })
      
    },
    
    watch: {
      options: {
      immediate: true,
      handler(val)
      {
        if (this.select_adv) this.select_adv.setValues(val)
      }
      }
    },
}
</script>
