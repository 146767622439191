<template>
<div>
  <h4 v-if="title"><span v-html="title"></span> <small style="font-size: 60%" class="label label-success"><span v-if="query" v0>Найдено: </span>{{common.formatNumber(total)}}</small></h4>
  <div class="btn-toolbar table-toolbar" role="toolbar" aria-label="..." style="margin-bottom: 5px; margin-left: 0;">
    <Toolbar ref="toolbar" :buttons=toolbar @toolbar-click=toolbarClick />
    <div class="btn-group pull-right" role="group" >
      <PagerView :total=total :current=currentPage :per-page=perPage @set-page=setPage />
    </div>
  </div>
  <div class="table-responsive">
  <table class="table table-striped table-bordered table-hover table-my-sorted" style="margin-bottom: 0px;" v-if="ready">
    <thead>
    <tr>
      <th name="time_unix" style="width: 9%">Дата</th>
      <th v-if="showAdmin" name="admin">Инициатор</th>
      <th v-if="showClient" name="admin">UID</th>
      <th v-if="showEntity" name="entity">Объект</th>
      <th v-for="c in columns" 
       :name=c.name :style="{width: c.width+'%'}" 
      >
        <Icon :name=c.icon v-if="c.icon" /> {{c.title}}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="rec in rows" :class=actionClass(rec.action)>
          <td :title=rec.rationale>{{ common.fromUnixtime(rec.time_unix) }}</td>
          <td v-if="showAdmin">
            <a v-if="rec.admin.match(/^\$/)" :href="`/worker/${rec.admin}`"><Icon name="fa-desktop" />&nbsp;{{rec.admin}}</a>
            <a v-else-if="rec.admin.match(/^uid:/)" :href="`/client/${getUid(rec.admin)}`"><Icon name="user" />&nbsp;{{getUid(rec.admin)}}</a>
            <a v-else :href="`/worker/${rec.admin}`">{{rec.admin}}</a>
          </td>
          <td v-if="showClient"><a v-if="rec.uid" :href="`/client/${rec.uid}`">{{rec.uid}}</a></td>
          <td v-if="showEntity" :title=rec.entity >{{entityHumanName(rec)}}</td>
          <td><Icon :name=actionIcon(rec.action) :title=actionHint(rec.action) /></td>
          <td v-if="isLong(rec)"><Flipper :shy=true title="               …                  ">
          <HistoryRow :row=rec :class=actionClass(rec.action) /></Flipper></td>
          <td v-else><HistoryRow :row=rec :class=actionClass(rec.action) /></td>
      </tr>
    </tbody>
  </table>
  </div>
  
</div>
</template>

<script>
  import _ from "underscore"
  import darsan from "darsan"
  import $ from "jquery"
  
  import {changeURLParam} from "navigation"

  import Toolbar from "common/visual/Toolbar.vue"
  import PagerView from "common/visual/PagerView.vue"
  import HistoryRow from "common/visual/history/HistoryRow.vue"
  import Flipper from "common/visual/Flipper.vue"
  
  export default {
    
    name: "HistoryTable",
    
    components: {Toolbar, PagerView, HistoryRow, Flipper},
    
    props: {
      // Внутреннее имя таблицы, напр. для сохранения макета в настройках пользователя
      name: {
        type: String,
        required: true,
      },
      title: String,
      // Начальное состояние таблицы (строка в виде ?page=1&query=xxx&sort=-a)
      state: {
        type: String,
        default: null,
      },
      apiPath: String,
      
      // Синхронизировать URL с состоянием компонента 
      // (менять в урл параметры сортировки, страницы, запроса ?page=1&query=xxx&sort=-a)
      syncURL: {
        type: Boolean,
        default: true,
      },
      
      // Запрос по страницам
      paged: Boolean,

      toolbar: {
        type: Array,
        default: () => []
      },
      showClient: {
        type: Boolean,
        default: false,
      },
      showEntity: {
        type: Boolean,
        default: false,
      },
      showAdmin: {
        type: Boolean,
        default: false,
      },

    },
    
    expose: [ "reloadTable", "setQuery", "setPage" ],
    
    data()
    {
      return {
        ready: false,
        rows: [],
        query: null,
        currentPage: 0,
        perPage: +this.$store.state.userSettings.rowsPerPage || 25,
        fetchFunction: this.paged ? this.pagedFetchRows : this.simpleFetchRows,
        total: 0,
        isActivated: false,
        columns: [
          {
            name: "action",
            title: " ",
            width: 1,
          },
          {
            name: "new",
            title: " ",
            width: 80,
          },
        ],
      }
    },
    
    emits: [ "toolbar-click", "load-query" ],
    
    created()
    {
      this.initFromState()
      
      this.$store.watch(
        (state, getters) => state.refreshTable[this.name],
        (val) => 
        {
          if (val==1) this.fetchRows()
        })
        
      this.fetchRows = _.throttle(this.eagerFetchRows, 300, {leading: false})
    },

    activated()
    {
      this.isActivated = true
    },
    
    deactivated()
    {
      this.isActivated = false
    },
    
    methods: {
      // Требование перезагрузки таблицы
      reloadTable()
      {
        this.fetchRows()
      },
      // Событие от SearchPanel
      setQuery(q) 
      {
        this.query = q
        this.currentPage = 1
      },
      // Событие от SearchPanel
      setPage(page)
      {
        this.currentPage = page
      },
      ////////////////////
      setPage(p)
      {
        this.currentPage = p
      },
      
      eagerFetchRows()
      {
        if (!this.isActivated) return
        
        this.ready = false

        this.fetchFunction({
          from: (this.currentPage-1)*this.perPage, 
          count: this.perPage, 
          sortField: this.sortField, 
          sortAsc: this.sortAsc, 
          query: this.query
        })
        .then(rec => 
        {
          this.total = +rec.total
          this.rows = rec.data
          
          this.$store.commit("wasRefreshed", this.name)
          
          return Promise.all(this.promiseEntities(rec.data))
        })
        .then(() => this.ready = true)
      },
      
      promiseEntities(list)
      {
        return _.uniq(list.map(el => el.system).filter(el => el))
          .filter(el => ! this.$store.getters.hasEntitiesFrom(el))
          .map(el => this.$store.dispatch("loadEntitiesFrom", el))
      },
      
      pagedFetchRows(args)
      {
        const params = {
          from: args.from,
          count: args.count
        }
  
        if (args.query) params.query = args.query
        return darsan.get("", "history", this.apiPath, params)
      },
      
      // упрощенная функция, если запрос не возвращает страниц
      simpleFetchRows(args)
      {
        return darsan.get("", "history", this.apiPath)
          .then(list => ({data: list, total: list.length}))
      },
      
      toolbarClick(msg)
      {
         this.$emit("toolbar-click", msg)
      },
      
      initFromState()
      {
        const params = new URLSearchParams(this.state)

        this.query = params.get("query")
        this.$emit("load-query", this.query)

        const p = params.get("page")
        const s = params.get("sort")
        
        this.currentPage = p ? +p : 1
        
        if (s)
        {
          const res = s.match(/^(-?)(.*)/)
          this.sortField=res[2]
          this.sortAsc = res[1] !== "-"
        }
      },
      
      actionClass(action)
      {
        return actionAttr[action] ? actionAttr[action][0] : ""
      },

      actionIcon(action)
      {
        return actionAttr[action] ? actionAttr[action][1] : "fa-question"
      },

      actionHint(action)
      {
        return actionAttr[action] ? actionAttr[action][2] : action
      },
      
      isLong(rec)
      {
        const n = rec.new instanceof Object ? Object.keys(rec.new).length : 1
        const o = rec.old instanceof Object ? Object.keys(rec.old).length : 1
        return Math.max(n, o) > 2
      },
      
      getUid(admin)
      {
        const m = admin.match(/^uid:(\d+)/)
        return m[1]
      },
      
      entityHumanName(rec)
      {
        const ent = this.$store.getters.matchEntity(rec.system, rec.entity)
        return ent ? this.$store.getters.entity(rec.system, ent).human : rec.entity
      },
      
    },
    
    watch: {
      state: function(val) { this.initFromState(); this.fetchRows() },
      apiPath: function(val) { this.fetchRows() },
      query: function(val) 
      { 
        if (this.syncURL) changeURLParam("query", val || null) 
        this.fetchRows()
      },
      currentPage: function(val) { if (this.syncURL) changeURLParam("page", val!=1 ? val : null); this.fetchRows() },
    },
    
  }
  
const actionAttr = {
  "delete": ["danger","fa-minus", "Удалено"],
  "change": ["", "transfer", "Изменено"],
  "add": ["success", "fa-plus", "Добавлено"],
  "peek": ["info", "fa-eye", "Просмотр"],
  "disconnect": ["info", "fa-unlink", "Сброс сессии"],
  "start": ["info", "fa-play", "Запуск"],
  "stop": ["info", "fa-stop", "Остановка"],
}  
  
</script>

<style scoped>
  table {
    max-width: 99.5%;
  }

  table.collection-list tr.selected {
    color: rgb(51, 51, 51);
    background-color: #d9edf7;
  }

  .menu {
    padding-left: 7px;
    padding-right: 7px;
  }
  
  table.table td 
  {
    vertical-align: middle;
  }

  @media screen  and (max-device-width: 480px) and (orientation: portrait) {
    .table-responsive {

    }

    .table-toolbar {
      min-height: 80px;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      margin-bottom: 5px;
      margin-left: 0px;
      align-items: flex-start;
    }
  }

  @media screen  and (min-device-width: 1024px) and (orientation: landscape)  {
    .table-responsive {
      height: calc(100vh - 160px);
    }
  }

</style>
