<template>
<div class="main">
  <Console ref="console" />    
</div>
</template>

<script>
import {setDebug, setExtra, initFacts, compileRules, runRules} from "master/rules/engine.js"
import darsan from "darsan"
import {notifyError} from "common"
import Console from "common/visual/Console.vue"

export default {
  name: "Helper",
  
  components: {
    Console,
  },
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      uid: null,
      dialog: null,
      master: null,
    }
  },
  
  methods: {
  
    async loadFacts()
    {
      return darsan.get("", "client", this.master+"/fact")
    },
    
    async loadRules()
    {
      return darsan.get("", "client", this.master+"/rule")
    },
    
  },
  
  watch: {
    state: {
      immediate: true,
      deep: true,
      async handler(val)
      {
        if (!('uid' in val)) return 
        this.uid = val.uid
        this.dialog = val.dialog
        this.master = val.master

        // rules и загруженные шаблоны фактов по-хорошему надо кэшировать
        // setDebug(true)
        setExtra({
          print: (sec, type, text) => this.$refs.console.print(sec, type, text),
        })
        const rules = compileRules(await this.loadRules())
        const facts = initFacts(await this.loadFacts(), {uid: this.uid})
        runRules(rules, facts)
      }
    },
  },
}   
</script>

<style scoped>
  .main {
    width: 60vw;
  }
</style>
