<template>
  <div>
  <h4 v-show="error">{{error}}</h4>
  <div class="row" v-show="!error">
    <div class="col-md-8">
      <Tabs :tabs=clientTabs :state=state :info-tab-title=login :uiList=$store.state.userSettings.uiClientTabs />
    </div>
    <div class="col-md-4">
      <Panel title="Новости" icon=home :toolbar=newsToolbar :low-toolbar=true @toolbar-click=newsClick >
        <RadixModule ref=news path="/client/news/news" :state=state />
      </Panel>
      <Panel title="Заметки" icon=comment :toolbar=noteToolbar :low-toolbar=true @toolbar-click=noteClick >
        <RadixModule ref=notes path="/client/note/note" :state=state />
      </Panel>
      <Panel title="Деньги" icon=usd :toolbar=moneyToolbar :low-toolbar=true :padding=true @toolbar-click=moneyClick >
        <RadixModule ref=money path="/client/money/money" :state=state />
      </Panel>
      <Panel title="Заявки" icon=tasks :toolbar=taskToolbar :low-toolbar=true @toolbar-click=taskClick >
        <RadixModule ref=tasks path="/client/task/list" :state=state />
      </Panel>
    </div>
  </div>
  </div>
</template>

<script>
import RadixModule from "RadixModule.vue"
import Tabs from "common/visual/Tabs.vue"
import Backbone from "backbone-ex"
import radio from "radio"
import * as darsan from "darsan"
import {setTitle} from "navigation"
import * as clientTabs from "client/page/clientTabs"
import {domainByUid} from "sysconf.js"
import * as dialog from "common/dialog-bs/dialog"

let model, loadPromise

export default {
  name: "ClientPanel",
  components: {RadixModule, Tabs},
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
    
      login: null,
      
      error: null,
      
      newsToolbar: [
        {
          name: "all",
          toggle: ["Все","Активные"],
          opened: false,
        },
      ],
      
      noteToolbar: [
        {
          name: "add",
          icon: "plus",
          text: "Добавить",
        },
      ],
      
    }
  },
  
  created()
  {
    const infoModel = Backbone.ModelEx.extend({
      idAttribute: "entity"
    })
                        
    model = new infoModel();
    
    radio.channel('owners').reply('model:client:info:promise', () => 
    {
      if (loadPromise)
      {
        return loadPromise.then(model => model)
      }
      else
      {
        return $.Deferred().resolve(model).promise()
      }
    })  

    this.setState()
  },
  
  methods: {
    
    newsClick(msg)
    {
      if (msg.from=="all")
      {
        this.$refs.news.invoke("toggleHide", msg.opened)
      }
    },
    
    noteClick(msg)
    {
      if (msg.from=="add")
      {
        this.$refs.notes.invoke("toggleAdd")
      }
    },
    
    moneyClick(msg)
    {
      switch(msg.from)
      {
        case "temp-credit":
           this.$refs.money.invoke("temporaryCredit")
        break
        case "accounts":
          this.$refs.money.invoke("toggleAccount")
        break
        case "payments":
          this.$refs.money.invoke("showPayments")
        break;  
        case "reductions":
          this.$refs.money.invoke("showReductions")
        break;  
      }
    },

    async taskClick(msg)
    {
      switch(msg.from)
      {
        case "show":
           this.$refs.tasks.invoke("toggleList")
        break
        case "oper":
//          var d = await dialog.showComponent("issue/page/issue-page", "Мастер оформления заявки", { uid: this.state.uid, dialog: dialog })
//          d.once('close', () => this.$refs.tasks.invoke("toggleList"))
          await dialog.showComponent("helper/page/helper-page", "Помощник оператора", { uid: this.state.uid, dialog: dialog, master: "/master/1" })
        break
        case "add":
          this.$refs.tasks.invoke("newTask")
        break;  
      }
    },
    
    setState()
    {
      this.state.uid = this.state.id
      
      this.error = "Загружаю данные..."
      loadPromise = this.getClientInfo()
      .done( () => 
      {
        const toShow = this.$store.state.config.local_flavor.show_client_login ? model.get("login") : this.state.uid
        this.login = toShow      
        setTitle(toShow)

        this.error = null
      })
      .catch((model, xhr) => 
      {
        this.error = xhr.status==404 ? `Абонент ${this.state.uid} не найден` : darsan.errorText()
      })
      .always(() => loadPromise = null)
    },        

    getClientInfo()
    {
      const prefix = domainByUid(this.state.id)
      model.url = darsan.makeURL(prefix, "client", "/client/" + this.state.uid)
      
      return model.fetchm()
    },        
    
  },
  
  computed: {
    moneyToolbar()
    {
      const buttons = []
    
      if (this.$store.getters.permGet('billing.client.credit.temporary.set')) 
      {
        buttons.push({ name: "temp-credit", text: "", icon: "time"})
      }
                      
      if ( this.$store.getters.permGet( "billing.money.list" ) ) 
      {
        buttons.push(
          { name: "payments", text: "Оплаты", icon: "plus" },
          { name: "reductions", text: "Снятия", icon: "minus" }, 
          { name: "accounts", icon: "shopping-cart", opened: false, toggle: ["Доп. счета","Доп. счета"]  },
        )
      }
      
      return {name: "money-toolbar", buttons, low: true}
    },

    taskToolbar()
    {
      const toolbar = [
       { name: "show", opened: false, toggle: ["Все","Активные"] },
      ]
      
      if ( this.$store.getters.permCanWrite("task.data") ) 
      {
        toolbar.unshift(
          { name: "oper", text: 'Помощник', icon: "fa-bolt" },
          { name: "add", text: 'Добавить', icon: "plus" },
        )
      }

      return toolbar
    },
    
    clientTabs()
    {
      return clientTabs.default
    },
    
  },
  
  watch: {
    state(val) { this.setState() },
  },

}

</script>

