<template>

  <div>
    <SearchTable v-if="ready" ref=table name="nas" title="Серверы доступа" :columns=columns dynamic 
     apiTopic=client apiPath="/nas?paged=1" 
     metaApiTopic="client" metaApiPath="/meta/nas/search"
     :rowClass=rowClass :toolbar=toolbar :state=state.params
     @toolbar-click=toolbarClick
    >
      
      <template #name="{rec}">
        <a :href="'/nas/' + rec.id">{{ rec.name }}</a>
      </template>

      <template #disable="{rec}">
        <span v-if="rec.disable" class='glyphicon glyphicon-minus' aria-hidden='true'></span>
        <span v-else>&#10003;</span>
      </template>

    </SearchTable>

    <SkelSearchTable v-else />

  </div>
</template>
  
<script>
  import darsan from "darsan"
  import SkelSearchTable from "common/visual/SkelSearchTable.vue"
  import { goto } from "navigation"

  export default {
    name: "NasTable",
    
    components: { SkelSearchTable },
  
    props: {
      state: Object,
    },
    
    data () {
      return {
        ready: false,
        
        toolbar: [
          {
            name: "add",
            icon: "plus",
            text: "Добавить",
          },
        ],
        
        columns: [
          {
            name: "id",
            title: "ID",
            sort: true,
          },
          {
            name: "name",
            title: "Название",
            sort: true,
          },
          {
            name: "disable",
            title: "Статус",
            sort: "true",
          },
          {
            name: "ip",
            title: "IP адрес",
            sort: true,
          },
          {
            name: "type",
            title: "Тип",
            sort: true,
          },
          {
            name: "description",
            title: "Описание",
            sort: true,
          },
        ],
        
      }
    },
    
    created () {
      this.ready = true
    },
    
    activated () {
      if (this.$refs.table) this.$refs.table.reloadTable()
    },
    
    methods: {
      rowClass (rec) {
        if (rec.disable) return "warning"
      },

      toolbarClick (msg) {
        if (msg.from == "add") {
          goto("/nas/!new")
        }
      },
    },
  }
</script>
