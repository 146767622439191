<template>
  <TextArea v-if="at.type=='string' && at.len && at.len>100" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :maxlength="+at.len" :rows=rows
    :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)" />
  <Input v-else-if="at.type=='string'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :maxlength="Number(at.len)"
    :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)" />
  <Number v-else-if="at.type=='integer'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :min="+at.min" :max="+at.max" :step="+at.step"
    :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)" />
  <Select v-else-if="at.type=='one-of'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info :withBlank=withBlank
    :options=at.options 
    :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)" />
  <Boolean v-else-if="at.type=='boolean'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :modelValue=value @update:modelValue="$emit('update:modelValue', $event)" />
  <LocationInput v-else-if="at.type=='geo'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :modelValue=modelvalue @update:modelValue="$emit('update:modelValue', $event)" />
  <Phone v-else-if="at.type=='phone'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)" />
  <Input v-else-if="at.type=='e-mail'" type=email :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)" />
  <Currency v-else-if="at.type=='money'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)" />
  <span style="color: red" v-else>GenInput: Unknown attribute type: {{at.type}}</span>
</template>

<script>

import Input from "common/visual/input/Input.vue"
import TextArea from "common/visual/input/TextArea.vue"
import Select from "common/visual/input/Select.vue"
import Number from "common/visual/input/Number.vue"
import Date from "common/visual/input/Date.vue"
import Currency from "common/visual/input/Currency.vue"
import LocationInput from "common/visual/input/LocationInput.vue"
import Phone from "common/visual/input/Phone.vue"
import Boolean from "common/visual/input/Boolean.vue"

export default {

  name: "GenInput",
  
  components: {Input, TextArea, Select, Number, Date, Currency, LocationInput, Phone, Boolean},
  
  emits: ['update:modelValue'],
  
  props: {
    apiTopic: {
      type: String,
    },
    entity: String,
    attr: String,
    attribute: Object,
    size: Number,
    labelSize: Number,
    modelValue: {
       type: [String, window.Number, window.Boolean],
       default: "",
    },
    rows: Number,
    withBlank: Boolean,
    error: String,
  },
  
  computed: {
    at()
    {
      return this.attribute || this.$store.getters.attr(this.apiTopic, this.entity, this.attr)
    },
    
    label()
    {
      return this.at.human || this.at.text
    },
  },
  
}
</script>