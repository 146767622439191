<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px">
    <label :for=uniqueId class="control-label" :class="'col-sm-'+labelSize" >{{label}}</label>
      <div :class="'col-sm-'+size">
          <select class="form-control" :name=name :id=uniqueId @change=newValue @blur=newValue @focus=savePrevious >
            <option v-if="withBlank" value="" :selected="modelValue==''" ></option>
            <option v-for="o in options" :key="o[valueName]" :value="o[valueName]" :selected="o[valueName]==modelValue" >{{o[textName]}}</option>
          </select>
      
    <Icon v-if="has_undo" name=fa-reply class="form-control-feedback opacity-half-full"
      style="cursor: pointer; pointer-events: auto;" @click.native=undo />      

     <span class="help-block">{{error}}</span>
    </div>
    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>
  </div>
</template>

<script>

import CooperMixin from "common/visual/cooper/CooperMixin.vue"

export default {
  name: "Select",
 
  mixins: [CooperMixin],

  data() 
  {
    return {
      element: "select",
    }
  },
    
  props: {
    options: {
      type: Array,
    },
    // Добавить пустой вариант ""
    withBlank: {
      type: Boolean,
      default: false,
    },
    textName: {
      type: String,
      default: "text",
    },
    valueName: {
      type: String,
      default: "value",
    },
 },
  
}
</script>
