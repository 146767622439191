define(['jquery', 'underscore', 'backbone'], function($, _, Backbone) {
  var common = {};

  common.advData = {
    type: [
	{ id: 'switch', name: 'Switch' },
	{ id: 'smart', name: 'Switch (Smart)' },
	{ id: 'pon', name: 'PON' },
	{ id: 'pontree', name: 'PONtree' },
    ],
    ipoe_class: [
      { entity: 'pppoe', name: 'PPP' },
      { entity: 'ipoe-aggr', name: 'Агрегатор IPOE' },
      { entity: 'ipoe-access', name: 'Пользовательское IPOE' }
    ],
    state: [
      {
        entity: 'down',
        name: 'Проблема',
        element: '<span class="icon glyphicon glyphicon-remove-sign text-danger"></span>',
        classState: 'danger',
        classLabel: 'label-danger'
      },
      {
        entity: 'up',
        name: 'Ок',
        element: '<span class="icon glyphicon glyphicon-ok-sign text-success"></span>',
        classState: 'success',
        classLabel: 'label-success'
      },
      {
        entity: 'unknown',
        name: 'Неизвестно',
        element: '<span class="icon glyphicon glyphicon-question-sign text-muted"></span>',
        classState: 'muted',
        classLabel: 'label-default'
      },
      {
        entity: 'disabled',
        name: 'Отключено',
        element:
          '<div style="display: inline-block;width: 13px;height: 13px; background-color: grey; border-radius: 50%;"></div>',
        classState: 'muted',
        classLabel: 'label-default'
      },
      {
        entity: 'old-up',
        name: 'Работал 10 мин. назад',
        element: '<span class="icon glyphicon glyphicon-ok-sign text-success"></span>',
        classState: 'grayed',
        classLabel: 'label-default'
      }
    ],

    onuState: {
      getOnuState: function(data) {
        let title = '';
        let alertLevel = 0;
        let className = '';


        let tempState = {};
      	let oltRxState = {};
      	let onuRxState =  {};

	if( data.onu_deregistered ){

      tempState = { className: 'text-muted' };
	    oltRxState = { className: 'text-muted' };
	    onuRxState = { className: 'text-muted' };
	    className = 'text-muted';

	}else{

          tempState = this.getTemperatureState(data.onu_temperature);
	        oltRxState = this.getLevelState(data.onu_olt_rx);
    	    onuRxState = this.getLevelState(data.onu_onu_rx);

          title += tempState.text || '';

	    if (tempState.alertLevel >= alertLevel) {
      		alertLevel = tempState.alertLevel;
        	className = tempState.className;
    	}

    	    title += oltRxState.text || '';
    	    if (oltRxState.alertLevel >= alertLevel) {
        	alertLevel = oltRxState.alertLevel;
        	className = oltRxState.className;
    	    }

    	    title += onuRxState.text || '';
    	    if (onuRxState.alertLevel >= alertLevel) {
        	className = onuRxState.className;
    	    }

    	    if (!title) {
        	title = 'Проблем не выявлено';
    	    }
	}

        return {title, className, detail: {
          temperatureClassName: tempState.className,
          oltRxClassName: oltRxState.className,
          onuRxClassName: onuRxState.className,
        }}
      },
      undefState: {
        className: 'text-muted',
        alertLevel: 0
      },
      levelState: {
        good: {
          className: 'text-success',
          text: () => '',
          alertLevel: 0
        },
        normal: {
          className: 'text-warning',
          text: (level) => `Низкий уровень сигнала от ONU или OLT (${level} dB)\n`,
          alertLevel: 1
        },
        bad: {
          className: 'text-danger',
          text: (level) => `Низкий уровень сигнала от ONU или OLT (${level} dB)\n`,
          alertLevel: 2
        }
      },
      tempState: {
        good: {
          className: 'text-success',
          text: () => '',
          alertLevel: 0
        },
        normal: {
          className: 'text-warning',
          text: (temperature) => `Высокая температура устройства (${temperature}&#176C)\n`,
          alertLevel: 1
        },
        bad: {
          className: 'text-danger',
          text: (temperature) => `Критически высокая температура устройства (${temperature}&#176C). Возможен выход из строя\n`,
          alertLevel: 2
        }
      },
      getLevelState: function(level) {
        if (typeof level === 'undefined') {
          return Object.assign({}, this.undefState);
        }

        if (level <= 50 ) {
          const state = Object.assign({}, this.levelState.good);
          state.text = state.text(level);
          return state;
        }

        if (level <= 70) {
          const state = Object.assign({}, this.levelState.normal);
          state.text = state.text(level);
          return state;
        }

        const state = Object.assign({}, this.levelState.bad);
        state.text = state.text(level);
        return state;
      },
      getTemperatureState: function(temperature) {
        if (typeof temperature === 'undefined') {
          return Object.assign({}, this.undefState);
        }

        if (temperature <= 50 ) {
          const state = Object.assign({}, this.tempState.good);
          state.text = state.text(temperature);
          return state;
        }

        if (temperature <= 70) {
          const state = Object.assign({}, this.tempState.normal);
          state.text = state.text(temperature);
          return state;
        }

        const state = Object.assign({}, this.tempState.bad);
        state.text = state.text(temperature);
        return state;
      },
    }
  };

  common.urlData = {
    get: function() {
      var url = _.unescape(Backbone.history.location.search).slice(1),
        obj = {};
      if (url.length) {
        url.split('&').map(function(v) {
          v = v.split('=');
          obj[v[0]] = decodeURIComponent(v[1]);
        });
      }
      return obj;
    },
    set: function(obj, url, param) {
      var url = url || Backbone.history.location.pathname,
        array = [];
      _.each(obj, function(v, k) {
        if (!v) return;
        array.push(k + '=' + v);
      });
      Backbone.history.navigate(url + '?' + array.join('&'), param || { trigger: true });
    }
  };

  common.urlData2 = {
	get: function( param ){

	    var url = _.unescape(Backbone.history.fragment);
	    var obj = {}, path = _.compact( url.split('/') );

	    _.map(path, function(v,k){
		if( _.contains(param, v)) obj[v] = path[++k];
	    });
	    return obj;
	},
	set: function( obj ){
	    var url = _.unescape(Backbone.history.fragment);
	    var path = _.compact( url.split('/') );

	    _.map(obj, function(v,k){
		var ind = _.indexOf(path,k);
		if( ind == -1 ){
		    if( v != undefined ) path.push(k,v);
		}else{
		    if( v == undefined ){ 
			path.splice(ind, 2);
		    }else{
			path[++ind] = v;
		    }
		}
	    });
	    Backbone.history.navigate(path.join('/'));
	}
  };

  common.websocket = function(param) {
    param = param || {};

    var socket = new WebSocket(param.url);
    socket.onopen = function() {
      console.info('Аnnouncer: соединение установлено!');
    };
    socket.onerror = function(event) {
      console.warn('Аnnouncer: соединение разорвано!');
      if (_.isFunction(param.error)) param.error(event);
      if (param.reconnect)
        setTimeout(function() {
          common.websocket(param);
        }, 15000);
    };
    socket.onmessage = function(event) {
      if (_.isFunction(param.success)) param.success(event);
    };
  };

  common.size_position_dialog = function( pt ){

	var w = $(window).width(), h = $(window).height();
	var wp = { id: 'device-status-window', width: '1000px', height: 'auto' };

	if( w < 1000 ){
		wp.width = w - 20;
		wp.height = h - 20;
		setTimeout(function(){$('#'+wp.id).css({ overflow: 'auto' }).closest('.ui-dialog').css({ position: 'fixed', top: '10px', left: '10px' }); }, 200);
	}else{
		if( pt ) wp.height = h - 40;
		setTimeout(function(){$('#'+wp.id).css({ overflow: 'auto' }).closest('.ui-dialog').css({ position: 'fixed', top: '10px' }); }, 200);
	}
	return wp;
  }
  return common;
});
