<template>
    <div class="well">
      <DynaList :apiDomain=domain apiTopic=client :baseUrl="`/client/${uid}/contact`" addText="Добавить контакт" :withTitle=true >
        <template #data>
          <Dictionary :apiDomain=domain apiTopic=client :baseUrl="makeMediaEnt(data.parent.entity)" :kinds="$store.getters.attr('client', 'contact-media', 'type').options" />
        </template>
      </DynaList>
    </div>
</template>

<script>
import DynaList from "common/visual/DynaList.vue"
import Dictionary from "common/visual/Dictionary.vue"
import SkelInput from "common/visual/SkelInput.vue"
import sysconf from "sysconf"

export default {
  
  name: "ContactList",
  
  components: {DynaList, Dictionary, SkelInput},
  
  props: {
    uid: String,
  },
  
  async created()
  {
    this.domain = sysconf.domainByUid(this.uid) || config.domain
  },
  
  data()
  {
    return {
      domain: null,
    }
  },
  
  methods: {
    makeMediaEnt(s)
    {
      return s.replace(new RegExp("^/client/\\d+"), "") + "/media"
    }
  },
}

</script>
