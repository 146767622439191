<template>
  <Tabs :tabs=workerTabs :state=state />
</template>

<script>
import Tabs from "common/visual/Tabs.vue"

export default {
  name: "WorkerPanel",
  components: {Tabs},

  props: {
    state: Object,
  },
  
  data()
  {
    return {
      workerTabs: [
        { modPath: 'worker/page/WorkerEditor', name: "workerEdit", text: "Редактор", defaultTab: true, subpath: "" },
        //{ modPath: 'worker/program/worker-program', text: "Программы", name: "workerProg", defaultTab: true, subpath: "programs" },
        { modPath: 'worker/ftag/worker-ftag', text: "Права", name: "workerPerm", defaultTab: true, subpath: "permissions" },
      ],
    }
  },
 
}
</script>
