import { createStore } from "vuex"

import darsan from "darsan"
import $ from "jquery"

import preload from "preload"

const state = {
  config: {},
  user: {},
  menu: [],
  ctrlPressed: false,
  urlRest: "",
  userSettings: {},
  ajaxCalls: 0,
  permissions: {},
  refreshTable: {},
  entities: {},
  entEndpoints: {},
  linkUid: null,
  
  // preload
  preload: {},
  currencyList: [],
  notificationTemplates: [],
  fopList: [],
  groupList: [],
  tplanList: [],
  actionList: [],
  clientTagList: [],
  tplanTypeList: [],
  qualityParamList: [],
  permTagList: [],
  nasTypeList: [],
  nasIpPoolList: [],
  nasList: [],
}

const alreadySent = {}

const mutations = {
  setConfig(state, data) { state.config = data },
  setUser(state, data) { state.user = data },
  setMenu(state, data) { state.menu = data },
  setCtrlPressed(state, data) { state.ctrlPressed = data },
  setUserSettings(state, data) { state.userSettings = data },
  setPermissions(state, data) { state.permissions = data },
  setUserSettingsClientTabs(state, data) { state.userSettings.uiClientTabs = data },
  setLinkUid(state, data) { state.linkUid = data },
  clearLinkUid(state, data) { state.linkUid = null },

  addAjaxCall(state) { state.ajaxCalls++ },
  delAjaxCall(state) { state.ajaxCalls-- },
  
  refreshTable(state, name) { state.refreshTable[name] = 1 },
  wasRefreshed(state, name) { state.refreshTable[name] = 0 },

  setPreload(state, [prefix, name, data]) { state.preload[`${prefix}/${name}`] = data },
  initPreload(state, [fullname, data]) { state.preload[fullname] = data },
  
  setEntityFrom(state, [system, data]) 
  { 
    state.entities[system] = data
    const endpoints = Object.fromEntries(Object.keys(data).map(name => [name, makeRegexpFromPic(data[name].pic)]))
    state.entEndpoints[system] = endpoints
  },
} 

const actions = {

   async preloadEnsure(ctx, list)
   {
     if (!Array.isArray(list)) list = [ list ]
     return preload.ensure.apply(preload, list).then((...args) => 
     {
       args.forEach(el => 
       {
         ctx.commit("setPreload", el)
       })
     })
   },

  async loadMenu(ctx)
  {
      const rec = await darsan.promiseDarsanToken2()

      const headers = {      
          "Authorization": "Darsan2 " + rec.token,
      };                     
                                                                 
       if (rec.pretend)       
       {
         headers["X-Darsan-Pretend"] = rec.pretend;
       }
                                                                                                   
       const menu = await $.ajax(API+"/user/menu",{headers})
       ctx.commit("setMenu", menu)
  },
  
  async loadEntitiesFrom(ctx, system)
  {
    try
    {
      const data = await darsan.getm("", system, "/meta/entity")
      ctx.commit("setEntityFrom", [system, data])
    }
    catch(err)
    {
      if (err.status != 404) console.log(err)
      ctx.commit("setEntityFrom", [system, []])
    }
  },
  
}

const getters = {
    permGet: state => tag => tag in state.permissions ? state.permissions[tag] : "",
                  
    permCanRead: (state,getters) => tag =>
    {
      var perm = getters.permGet(tag)
      return perm=="ro" || perm=="rw";
    },
                                          
    permCanWrite: (state,getters) => tag => getters.permGet(tag)=="rw",
    
    getPermTag: (state) => tag =>
    {
      return state.preload['/permission-tags'].find(el => el.name==tag) || {}
    },
    
    hasEntitiesFrom: state => system =>
    {
      return system in state.entities
    },
    
    entity: state => (system, name) =>
    {
      const s = state.entities[system]
      if (!s) throw new Error(`No entities from ${system} loaded`)
      const e = s[name]
      if (!e) throw new Error(`No entity loaded: ${system}/${name}`)
      return e
    },

    attr: (state, getters) => (system, entity, attr) =>
    {
      const a = getters.entity(system, entity).attr[attr]
      if (!a) throw new Error(`No attribute ${attr} in entity ${system}/${entity}`)
      return a
    },
    
    matchEntity: state => (system, path) =>
    {
      const e = state.entEndpoints[system]
      if (!e) return null

      for (name of Object.keys(state.entEndpoints[system]))
      {
        if (state.entEndpoints[system][name].test(path)) return name
      }
      
      return null
    },
}

function makeRegexpFromPic(pic)
{
  return new RegExp("^" + pic.replaceAll(/:\w+/g, "[^/]+") + "$")
}

export const store = createStore({
  state,
  mutations,
  actions,
  getters,
})
