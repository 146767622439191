<template>
<div style="padding-top: 1em">
  <table v-if="ready">
    <div class="row" v-for="i in 5">
      <div class="col-sm-2" v-for="j in 6">
        <ProgSwitch :data="prog(i,j)" v-if="prog(i,j)" :enabled="enabled[prog(i,j).name]" @toggle=toggle />
      </div>
    </div>
  </table>
  
  <template v-else>
    <div class="row" v-for="i in 5">
      <div class="col-sm-2" v-for="j in 6">
        <SkelLabel style="height: 4em" />
      </div>
    </div>
  </template>
</div>
</template>

<script>

import darsan from "darsan"
import SkelLabel from "common/visual/SkelLabel.vue"
import ProgSwitch from "worker/program/ProgSwitch.vue"
import {notifyHttpError} from "common"

export default {
  name: "WorkerProgram",
  
  components: {SkelLabel, ProgSwitch},
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      ready: false,
      programs: [],
      enabled: {},
    }
  },
  
  created()
  {
    darsan.get("", "darsan", '/permission-tag?query=name+LIKE+%22*.allowed%22')
    .then(list => 
    {
      this.programs = list
      this.ready = true
    })
    .catch(xhr => notifyHttpError(xhr))
  },
  
  activated()
  {
    darsan.get("", "darsan", '/worker/' + this.state.entity + '/permission?query=tag+LIKE+%22*.allowed%22')
    .then(list => 
    {
      this.enabled = Object.fromEntries(list.map(el => [el.tag, !!el.value]))
    })
    .catch(xhr => notifyHttpError(xhr))
  },
  
  methods: {
    
    prog(i,j)
    {
      return this.programs[(i-1)*6+j-1]
    },
    
    toggle(name)
    {
      const value = this.enabled[name] ? 0 : 1
      darsan.patch("", "darsan", "/worker/"+this.state.entity+"/permission/"+name, {value})
      .then( () => this.enabled[name] = !this.enabled[name] )
      .catch(xhr => notifyHttpError(xhr))
    },
  },
  
  computed: {
  },
  
}
</script>