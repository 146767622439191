<template>
    <div class="btn-group" role="group" v-if="total>perPage">
      <div class="">
        <ul class="pagination pagination-sm" style="margin: 0;">
          <li v-if="current==1" class='disabled'><a>«</a></li>
          <li v-else class='cursor-pointer'><a @click=setPage(current-1) >«</a></li>

          <template v-for="p in pages">
            <li v-if="p==current" class="active"><span>{{p}}</span></li>
            <li v-else class="cursor-pointer"><a @click=setPage(p) >{{p}}</a></li>
          </template>
  
          <li v-if="current==last" class='disabled'><a>»</a></li>
          <li v-else class='cursor-pointer'><a @click=setpage(current+1) >»</a></li>
        </ul>
      </div>
    </div>
</template>

<script>

  import _ from "underscore"
 
  export default {
  
    name: "pagerView",
    
    props: {
      current: {
        type: Number,
        default: 1,
      },
      total: {
        type: Number,
        default: 0,
      },
      perPage: {
        type: Number,
        default: 25,
      },
    },
    
    emits: [ "set-page" ],
    
    computed: {
      last() { return Math.ceil(this.total/this.perPage) },
      prev() { return this.current==1 ? null : this.current-1 },
      next() { return this.curr==this.last ? null : this.current+1 },
      pages() { return nicePages(this.current, this.last) }
    },
    
    methods: {
      setPage(p)
      {
        this.$emit("set-page", p)
      }
    },
  }
  
function nicePages(curr,last)
{
  curr = parseInt(curr);
  last = parseInt(last);
  var d = Math.floor(curr/10);
  var h = Math.floor(curr/100);
  var t = Math.floor(curr/1000);

  var pages = [1,last];

  pages = pages.concat(_.range(t-2,t).map(function(el) { return el*1000 }));
  pages = pages.concat(_.range(h-2,h).map(function(el) { return el*100 }));
  pages = pages.concat(_.range(d-2,d).map(function(el) { return el*10 }));
  pages = pages.concat(_.range(curr-3,curr));
  pages = pages.concat(_.range(curr,curr+3));
  pages = pages.concat(_.range(t,t+2).map(function(el) { return el*1000 }));
  pages = pages.concat(_.range(h,h+2).map(function(el) { return el*100 }));
  pages = pages.concat(_.range(d,d+2).map(function(el) { return el*10 }));

  return _.chain(pages)
    .filter(function(el) { return el>0 && el<=last })
    .sort(function(a, b) { return a-b })
    .uniq(true)
    .value();
}
  
</script>
