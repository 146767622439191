<template>
<div>
  <h4>Отсылка уведомления абонентам</h4>
  <div class="form-group">
    <label for="sms-message">Сообщение:</label>
    <textarea class="form-control" v-model="message" id="sms-message"></textarea>
  </div>

  <br>
  <button class="btn btn-secondary" @click=makeOp >Отослать</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-send-sms",
    
    props: {
      query: String,
    },
    
    emits: [ "dismiss" ],
    
    data()
    {
      return {
        message: "",
      }
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/sms', {
            message: this.message,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))

        this.$emit("dismiss")
      }
    },
  }
</script>
