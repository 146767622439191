<template>
  <div>
    <div class="slider" :style="{ left: sliderPos + 'px' }">
      <div class="slider-body well">
        <div style='overflow-y:auto;max-height:100vh;'>
          <div>
            <div><label style='margin-top:5px;margin-bottom:0px;'>Связки</label></div>
            <ul class='list'>
              <li v-for="item in connectives" :style="{background: item.color}" draggable="true" @dragstart="dragConnective(item.name)" >{{item.title}}
              </li>
            </ul>
          </div>
          <div>
            <div><label style='margin-top:5px;margin-bottom:0px;'>Условия</label></div>
            <ul class='cond list'>
              <li v-for="item in conditions" :style="{background: item.color}" draggable="true" @dragstart="dragCond(item.name)" >{{item.title}}
              </li>
            </ul>
          </div>
          <div>
            <div><label style='margin-top:5px;margin-bottom:0px;'>Действия</label></div>
            <ul class='list'>
              <li :style="{background: item.color}" draggable="true" v-for="item in actions" @dragstart="dragAction(item.name)">{{item.title}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="control" @click=sliderClick>Элементы</div>
    </div>

    <DynaList :apiDomain="''" apiTopic=client :baseUrl="`${entity}/rule`" addText="Новое правило" v-show="ready">
      <template v-slot="data">
        <Rule :rec=data.parent :apiPath=data.parent.entity :facts=facts />
      </template>
    </DynaList>
  </div>
</template>

<script>
import darsan from "darsan"
import DynaList from "common/visual/DynaList.vue"
import Rule from "master/page/Rule.vue"
import actions from "master/rules/actions.js"
import connectives from "master/rules/connectives.js"
import conditions from "master/rules/conditions.js"

export default {

  name: "MasterRules",

  components: {DynaList, Rule},
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      ready: false,
      sliderOpened: false,
      facts: [],
    }
  },
  
  async created()
  {
    this.facts = await darsan.get("", "client", this.entity + "/fact")
    this.ready = true
  },
  
  methods: {
    sliderClick()
    {
      this.sliderOpened = !this.sliderOpened
    },
    
    dragAction(name)
    {
      event.dataTransfer.setData("master-action", name)
    },

    dragConnective(name)
    {
      event.dataTransfer.setData("master-connective", name)
    },

    dragCond(name)
    {
      event.dataTransfer.setData("master-cond", name)
    }
  },
  
  computed: {
    entity()
    {
      return "/master/" + this.state.id
    },
    
    sliderPos()
    {
      return this.sliderOpened ? 0 : -400
    },
    
    actions()
    {
      return actions
    },

    conditions()
    {
      return conditions
    },
    
    connectives()
    {
      return connectives
    },
  },

}
</script>

<style scoped>
.slider{
  z-index: 10000;
  position: fixed;
  height: 100vh;
  top: 0px;
  display: flex;
  transition: left 0.4s linear;
}

.slider-body{
    height: 100%;
    width: 400px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
}

.slider .control{
    width: 40px;
    height: 100px;
    align-self: center;
    background-color: whitesmoke;
    border: 1px solid #dddddd;
    border-radius: 0px 3px 3px 0px;
    border-left: none;
    box-shadow: 3px 0 7px rgba(0,0,0,0.1);
    cursor: pointer;
    text-align: center;
    writing-mode: tb-rl;
    margin: 19px 0px; 
    font-size: 105%;
}

ul.list
{
    list-style: none;
    padding: 5px;
    margin: 0px;
}

ul.list li
{
  background: white;
  cursor: move;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0px;
  border: 1px solid lightgray;
}
</style>
