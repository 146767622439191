<template>

<SearchTable ref=table name="worker-perm" :title=breadCrumb :columns=columns dynamic 
 apiTopic=darsan :apiPath=apiPath
 metaApiTopic="darsan" metaApiPath="/meta/permission/search" 
 :toolbar=toolbar :fetch=fetchRows :state=query
 @toolbar-click=toolbarClick
>
  <template #name="{rec}">
    <a :href="'/worker/'+state.id+'/permissions/'+rec.tag.replace(/\./g,':')">{{$store.getters.getPermTag(rec.tag).name_ru}}</a>
  </template>

  <template #type="{rec}">
    {{$store.getters.getPermTag(rec.tag).type}}
  </template>

</SearchTable>

</template>

<script>

import darsan from "darsan"
import {goto} from "navigation"

export default {
  name: "WorkerTagTable",
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      toolbar: [
         {
            name: "add",
            icon: "plus",
            text: "Добавить",
          },
      ],
      columns: [
        {
          name: "name",
          title: "Название",
        },
        {
          name: "tag",
          title: "Разрешение",
        },
        {
          name: "type",
          title: "Тип",
        },
        {
          name: "value",
          title: "Значение",
        },
      ],
    }
  },
  
  created()
  {
    this.ensure("permission-tags")
  },
  
  methods: {
    toolbarClick({from})
    {
      if (from=="add")
      {
        goto("/worker/" + this.state.id + "/permissions/!new")
      }
    },
    
    fetchRows()
    {
      return darsan.get("", "darsan", `/worker/${this.state.id}/permission`)
       .then( data => { return { total: data.length, data } })
    },
    
    tagName(tag)
    {
      const rec = this.$store.getters.getPermTag(tag)
      return rec.name || "?"
    },

    tagType(tag)
    {
      const rec = this.$store.getters.getPermTag(tag)
      return rec.type || "?"
    },
  },
  
  computed: {
    breadCrumb()
    {
      return `<a href='/worker'>Работники</a> / <a href='/worker/${this.state.id}'>${this.state.id}</a> / Права`
    },
    apiPath()
    {
      return `/worker/${this.state.id}/permission`
    },
    query()
    {
      return this.state.query ? "?query="+this.state.query : ""
    }
  },
}
</script>
