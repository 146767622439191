<template>
<div style="margin-top: 1em">
    <div class="col-md-3 text-center">
        <ol class="breadcrumb">
          <li><a :href="`/worker/${this.state.id}`">{{this.state.id}}</a></li>
          <li><a :href="`/worker/${this.state.id}/permission`">Права</a></li>
          <li class="active">{{ rec.name }}</li>
        </ol>
        <div class="btn-group-vertical" role="group" aria-label="...">
          <a class="btn btn-default" :href="`/history/worker/${this.state.id}`">История изменений</a>
          <a class="btn btn-default text-danger" @click=deleteTag >
            <Icon name=trash /> Удалить
          </a>
        </div>

    </div>

    <div class="col-md-9" v-if="ready" >

        <div class="form-horizontal">

            <div class="well well-sm">

                <div class="form-group has-feedback">
                    <label for="tag" class="col-sm-2 control-label">Право</label>
                    <div class="col-sm-7" >
                        <p class="form-control-static">{{rec.name_ru}}</p>
                    </div>

                </div>

                <div class="form-group">
                    <label for="tag" class="col-sm-2 control-label">Описание</label>
                    <div class="col-sm-7" >
                        <p class="form-control-static">{{rec.description}}</p>
                    </div>
                </div>

         <Input v-if="rec.type=='string'" label="Значение" :size=7 :label-size=2 :name=rec.name apiTopic=darsan :apiPath=apiPath
          :info=rec.info v-model=value :save=save />
         <Number v-if="rec.type=='number'" label="Значение" :size=7 :label-size=2 :name=rec.name apiTopic=darsan :apiPath=apiPath
          :info=rec.info :min=rec.min :max=rec.max :step=rec.step v-model=value :save=save />
         <Select v-if="rec.type=='oneof'" label="Значение" :size=7 :label-size=2 :name=rec.name apiTopic=darsan :apiPath=apiPath
          :info=rec.info v-model=value :options=oneOfOptions :save=save />
         <Select v-if="rec.type=='darsan-oneof'" label="Значение" :size=7 :label-size=2 :name=rec.name apiTopic=darsan :apiPath=apiPath
          :info=rec.info v-model=value :options=darsanOneOfOptions :save=save />
         <Select2 v-if="rec.type=='darsan-array'" multiple label="Значение" :size=7 :label-size=2 :name=rec.name apiTopic=darsan :apiPath=apiPath
          :info=rec.info v-model=value :options=darsanArrayOptions :save=save />
         <Select v-if="rec.type=='boolean'" label="Значение" :size=7 :label-size=2 :name=rec.name apiTopic=darsan :apiPath=apiPath
          :info=rec.info v-model=value :options=yesNoOptions :save=save />

            </div>
        </div>

    </div>

  <template v-else>
    <SkelInput v-for="i in 9" :key=i />
  </template>
</div>

</template>

<script>

import Select from "common/visual/cooper/Select.vue"
import Select2 from "common/visual/cooper/Select2.vue"
import Input from "common/visual/cooper/Input.vue"
import Number from "common/visual/cooper/Number.vue"
import TextArea from "common/visual/cooper/TextArea.vue"
import SkelInput from "common/visual/SkelInput.vue"
import {notifyHttpError} from "common"
import {goto} from "navigation"
import darsan from "darsan"

export default {
  name: "WorkerTagEditor",
  
  components: {Select, Input, Number, TextArea, SkelInput, Select2},

  props: {
    state: Object,
    id: String,
  },
  
  data()
  {
    return {
      ready: false,
      value: null,
      list: [],
    }
  },
  
  created()
  {
    this.ensure("permission-tags").then(() => 
    {
      const type = this.rec.type
      if (type=="darsan-oneof" || type=="darsan-array")
      {
        darsan.get("", this.rec.topic, this.rec.path).then( list =>
        {
          this.list = list
          this.ready = true
        })
      }
      else
      {
        this.ready = true
      }
    })
  },
  
  activated()
  {
    darsan.get("", "darsan", `/worker/${this.state.id}/permission/${this.tag}`)
    .then(tagrec => 
    {
      if (this.rec.type=="boolean")
      {
        this.value = tagrec.value ? "1" : "0"
      }
      else
      {
        this.value = tagrec.value
      }
    })
  },
  
  methods: {
    save(val)
    {
      return darsan.patchJSON("", "darsan", this.apiPath, {value: val})
         .then(() => this.$store.commit("refreshTable", "worker-perm"))
    },
    deleteTag()
    {
      darsan.delete("", "darsan", this.apiPath)
        .then(() =>
        {
          this.$store.commit("refreshTable", "worker-perm")
          goto(`/worker/${this.state.id}/permission`)
        })
    },
  },
  
  computed: {
    tag()
    {
      return this.id.replace(/:/g,".")
    },
  
    rec()
    {
      return this.$store.getters.getPermTag(this.tag)
    },
    
    yesNoOptions() 
    {
      return [
        {value: "0", text: "false"},
        {value: "1", text: "true"},
      ]
    },
    
    oneOfOptions()
    {
      return this.rec.values.map(el => { return {text: el, value: el} })
    },
    
    darsanOneOfOptions()
    {
      return this.list.map(el => { return {text: el.text||el.display_name, value: el.value||el.entity} })
    },
    
    darsanArrayOptions()
    {
      return [{text: "Все", value: "*"}].concat(this.list.map(el => { return {text: el.text||el.display_name, value: el.value||el.entity} }))
    },
    
    apiPath()
    {
      return `/worker/${this.state.id}/permission/${this.tag}`
    },
  },
}
</script>