<template>
  <table>
    <tbody>
    <tr>
    <SingleRow :row=row.new :attr=attr v-if="row.action=='add'" />
    <SingleRow :row=row.old :attr=attr v-else-if="row.action=='delete' || row.action=='disconnect'" />
    <ChangeRow :oldRow=row.old :newRow=row.new :attr=attr v-else-if="row.action=='change'" />
  </tr>
  </tbody>
  </table>
</template>

<script>
import _ from "underscore"
import SingleRow from "common/visual/history/SingleRow.vue"
import ChangeRow from "common/visual/history/ChangeRow.vue"

export default {
  name: "HistoryRow",
  
  components: {ChangeRow, SingleRow},
  
  props: {
    row: Object,
  },
  
  data()
  {
    return {
      entity: "",
    }
  },
  
  created()
  {
    this.entity = this.$store.getters.matchEntity(this.row.system, this.row.entity)
  },
  
  methods: {
    attr(name)
    {
      if (!this.entity) return null

      try
      {
        return this.$store.getters.attr(this.row.system, this.entity, name)
      }
      catch
      {
        return null
      }
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
      
tbody tr:nth-child(even) {
  backdrop-filter: brightness(90%);
}

td.name {
  width: 10%;
  border-right: 1px solid lightgray;
  padding-left: 0;
  padding-right: 1em;
  font-weight: bold;
}

td {
  padding-left: 1em;
}

td.from {
  width: 45%;
  border-right: 1px solid lightgray;
}

</style>