const blue = "#daebff"
const red = "#ffdaeb"
const green = "#daffeb"
const yellow = "#D9CF9D"
const purple = "#EDE3FF"

import darsan from "darsan"

import {value} from "master/rules/value.js"
import sysconf from "sysconf"
import helpers from "master/rules/helpers.js"

function format(rec, str)
{
  return str.split(/(\{[^}]+\})/g)
  .map(el =>
  {
    const m = el.match(/\{([^}]+)\}/)
    if (!m) return el

    const expr = m[1]
    const pos = expr.indexOf("/")

    const name = pos==-1 ? expr : expr.substring(0, pos)
    const path = pos==-1 ? "" : expr.substring(pos+1)

    return value(rec[name], path)
  })
  .join("")
}

export default [
 {
   name: "fact.add",
   color: blue,
   title: "Добавить факт",
   template: "Добавить факт {fact:f}: {value:i}",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     facts[params.fact] = params.value
   },
 }, 
 {
   name: "fact.update",
   color: blue,
   title: "Изменить факт",
   template: "Изменить факт {fact:f}: {value:i}",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     facts[params.fact] = params.value
   },
 }, 
 {
   name: "fact.delete",
   color: blue,
   title: "Удалить факт",
   template: "Удалить факт {fact:f}",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     delete facts[params.fact]
   },
 },
 {
   name: "fact.debug",
   color: blue,
   title: "Вывести все факты",
   template: "Вывести все факты",
   fn(facts, params)
   {
     console.log(facts)
   },
 },
 ////////////////////////////
 {
   name: "http.get",
   color: green,
   title: "Запрос API",
   template: "Запросить {topic:i} {path:i} и добавить как факт {fact:f}",
   async fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     const domain = sysconf.domainByUid(facts.uid)
     const res = await darsan.get(domain, params.topic, format(facts, params.path))
     facts[params.fact] = res
   },
 },
 {
   name: "http.list_get",
   color: green,
   title: "Запрос API по списку",
   template: "Запросить по списку {list:f}/{path:i} API {topic:i} {apipath:i} и добавить как факт {fact:f}",
   async fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     if (!params.list) throw new Error("'list' is not defined")
     
     const list = value(facts[params.list], params.path)
     if (!Array.isArray(list)) throw new Error("http.list_get: not a list")
     
     const domain = sysconf.domainByUid(facts.uid)

     const res = await Promise.all(
       list.map((el,i) => darsan.get(domain, params.topic, format({i, it: el, ...facts}, params.apipath)))
     )

     facts[params.fact] = res
   },
 },
 {
   name: "http.getm",
   color: green,
   title: "Полный запрос API",
   template: "Запросить {topic:i} {path:i} и добавить как факт {fact:f} или как ошибку {err:f}",
   async fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     if (!params.err) throw new Error("'err' is not defined")
     try {
       const domain = sysconf.domainByUid(facts.uid)
       const res = await darsan.getm(domain, params.topic, format(facts, params.path))
       facts[params.fact] = res
     } catch(err)
     {
       facts[params.err] = err
     }
   },
 },
 /////////////////////////
 {
   name: "comp.extract",
   color: yellow,
   title: "Извлечь",
   template: "Извлечь из факта {fact:f}/{path:i} и добавить как факт {dst:f}",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     if (!params.dst) throw new Error("'dst' is not defined")
     facts[params.dst] = value(facts[params.fact], params.path)
   },
 },
 {
   name: "comp.merge",
   color: yellow,
   title: "Добавить к списку",
   template: "Добавить к списку {fact:f} данные {src:f}/{path:i}",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     if (!params.src) throw new Error("'src' is not defined")

     if (!facts[params.fact]) 
     {
       facts[params.fact] = []
     }
     else if (!Array.isArray(facts[params.fact]))
     {
       facts[params.fact] = [ facts[params.fact] ]
     }

     const src = value(facts[params.src], params.path)
     if (Array.isArray(src))
     {
       facts[params.fact] = facts[params.fact].concat(src)
     }
     else
     {
       facts[params.fact].push(src)
     }
   },
 },
 ////////////////////////
 {
   name: "execute",
   color: purple,
   title: "Выполнить",
   template: "Выполнить {helper:h}",
   async fn(facts, params)
   {
     if (!params.helper) throw new Error("'helper' is not defined")
     const rec = helpers.find(el => el.name==params.helper)
     if (!rec) throw new Error("Unknown helper: " + params.helper)
     await rec.fn(facts)
   },
 },
  
 ///////////////////////////
 {
   name: "console.info",
   color: red,
   title: "Вывести",
   template: "Вывести  в раздел {section:i}: {value:I}",
   fn(facts, params, extra)
   {
     extra.print(params.section, "info", format(facts, params.value))
   }
 }, 
 {
   name: "console.list",
   color: red,
   title: "Вывести список",
   template: "Вывести для списка {fact:f}/{path:i} в раздел {section:i} заголовок {header:i} и элементы {template:I}",
   fn(facts, params, extra)
   {
     if (!params.fact) throw new Error("'fact' is not defined")

     let list = value(facts[params.fact], params.path)
     if (!Array.isArray(list)) list = [ list ]
     
     if (params.header && list.length) extra.print(params.section, "header", params.header)
     extra.print(params.section, "list", list.map(el => format({it: el}, params.template)))
   }
 }, 
 {
   name: "console.error",
   color: red,
   title: "Ошибка",
   template: "Ошибка в разделе {section:i}: {value:I}",
   fn(facts, params, extra)
   {
     extra.print(params.section, "error", format(facts, params.value))
   }
 },
 {
   name: "send.telegram",
   color: red,
   title: "Отослать в Телеграм",
   template: "Отослать в Телеграм {id:i}: {value:I}",
   async fn(facts, params)
   {
     await darsan.post("", "client", "/srv/telegram/message", {to: params.id, message: format(facts, params.value)})
   }
 },
]
