<template>
<div class="col-md-12">
  <div class="panel panel-default" v-for="q in queues" :key=q.queue >
    <div class="panel-heading">{{q.title}}</div>
    <div class="panel-body">
      <div class="row">

        <div class="col-md-9">
          <OperatorList :list=q.members />
        </div>
  
        <div class="col-md-3">
          <div style="padding: 0 20px">
            <AbonQueue :list=q.abonents />
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
</template>

<script>

import AbonQueue from "phone/page/AbonQueue.vue"
import OperatorList from "phone/page/OperatorList.vue"
import darsan from "darsan"

export default {
  name: "Phone",
  
  components: {AbonQueue, OperatorList},
  
  async created()
  {
    const data = await darsan.get("", "phone", "/asterisk/queue")
    
    data.forEach(q =>
     {
      q.title = `${q.queue} — ${q.queue_name}`
    
      q.members.forEach(op =>
      {
        op.durationTrigger = Math.random()
      })
    })
    
    const list = await darsan.get("", "phone", "/asterisk/sip-peer")
    
    this.ensure(["worker"]).then( () => 
    {
      this.queues = data
    
      const peers = list
      .filter(el => el.name.match(/^\d+$/) && el.name>=100 && el.name<=150 && el.status.substr(0,2)=="OK" && !this.queueOfExten(el.name))
      .map(el =>
      {
        return {exten: el.name, status: "not-in-use", worker: this.workerByExten(el.name)}
      })

      this.queues.push({
        queue: 0,
        title: "Прочие",
        members: peers,
        abonents: [],
      })

    })
  },
  
  mounted()
  {
    announcers.forEach( ann => 
    {
      ann.whenReady().then( () =>
      {
        ann.subscribe("asterisk.queue.#")
        ann.subscribe("asterisk.exten.*")
        ann.listen({
          "asterisk.queue.abonent.join": this.addAbonent,
          "asterisk.queue.abonent.leave": this.deleteAbonent,
          "asterisk.queue.member.change": this.changeMemberStatus,
          "asterisk.exten.dial": this.addCaller,
          "asterisk.exten.link": this.addChannel,
          "asterisk.exten.change": this.extenStatusChanged,
        })
      })
    })
  },
  
  unmounted()
  {
    announcers.forEach( ann => 
    {
      ann.whenReady().then( () =>
      {
        ann.unsubscribe("asterisk.queue.#")
        ann.unlisten([
          "asterisk.queue.abonent.join",
          "asterisk.queue.abonent.leave",
          "asterisk.queue.member.change",
          "asterisk.exten.link",
          "asterisk.exten.dial",
          "asterisk.exten.change",
        ])
      })
    })
  },
  
  data()
  {
    return {
      queues: [],
    }
  },
  
  methods: {
    addAbonent(msg)
    {
      const qi = this.queues.findIndex(el => el.queue == msg.queue)
      if (qi==-1) return

      this.queues[qi].abonents.splice(0, 0, {
        position: +msg.position,
        caller: msg.caller,
        number: msg.number,
        id: msg.id,
        waited: 0,
      })
    },
    
    deleteAbonent(msg)
    {
      const qi = this.queues.findIndex(el => el.queue == msg.queue)
      if (qi==-1) return
      
      const i = this.queues[qi].abonents.findIndex(el => el.id == msg.id)
      if (i != -1) this.queues[qi].abonents.splice(i, 1)
    },
  
    changeMemberStatus(msg)
    {
      const queue = this.findQueue(msg.queue)
      if (!queue) return

      if (msg.status=="unavailable")
      {
         // Оператор ушел из очереди
         return this.deleteMember(queue, msg.exten)
      }
      
      const newRec = {
          status: msg.status,
          duration: 0,
          durationTrigger: Math.random(),
      }
        
      if (msg.last_call) newRec.last_call = +msg.last_call
      if (msg.calls_taken) newRec.calls_taken = +msg.calls_taken
      if (msg.status=="not-in-use") newRec.caller = null

      this.addOrUpdateMember(queue, msg.exten, newRec)
    },
    
    findQueue(number)
    {
      return this.queues.find(el => el.queue == number)
    },
    
    findExten(queue, ext)
    {
      return queue.members.find(el => el.exten == ext)
    },

    findWorker(queue, worker)
    {
      return queue.members.find(el => el.worker == worker)
    },
    
    addMember(queue, newRec)
    {
      queue.members.splice(queue.members.length, 0, newRec)
      
      // Если номер оказался в очереди, то удаляем его из очереди 0
      const q0 = this.findQueue(0)
      if (queue.queue!=0 && q0.members.find(el => el.exten==newRec.exten))
      {
        this.deleteMember(q0, newRec.exten)
      }
      
      return queue.members.length-1
    },

    updateMember(queue, ext, newRec)
    {
      const i = queue.members.findIndex(el => el.exten == ext)
      if (i == -1) return false
  
      const rec = Object.assign(queue.members[i], newRec)
      queue.members.splice(i, 1, rec)
      return true
    },
    
    addOrUpdateMember(queue, ext, diff)
    {
      if (ext>150) return // Пашу интересуют только операторы

      let i = queue.members.findIndex(el => el.exten == ext)
      if (i == -1)
      {
        i = this.addMember(queue,
        {
          exten: ext,
          worker: this.workerByExten(ext) || "???",
        })
      }
  
      const rec = Object.assign(queue.members[i], diff)
      queue.members.splice(i, 1, rec)
    },
    
    deleteMember(queue, ext)
    {
      const i = queue.members.findIndex(el => el.exten == ext)
      if (i == -1) return

      queue.members.splice(i, 1)
    },
    
    // вызывается по .dial
    addCaller(msg)
    {
      const diff = {
        status: "ringing",
        duration: 0,
        durationTrigger: Math.random(),
        channel: msg.channel,
        number: msg.number,
        caller: msg.caller,
        outgoing: msg.outgoing,
      }

      const updated = this.queues.filter(el => el.queue!=0).map(el => this.updateMember(el, msg.exten, diff))

      // если сип не нашелся в очередях, его надо добавить в нулевую
      if (!updated.some(el => el))
      {
        this.addOrUpdateMember(this.findQueue(0), msg.exten, diff)
      }
    },
    
    // вызывается по .link
    addChannel(msg)
    {
      const diff = {
        status: "in-use",
        duration: 0,
        durationTrigger: Math.random(),
        channel: msg.channel,
      }

      const updated = this.queues.filter(el => el.queue!=0).map(el => this.updateMember(el, msg.exten, diff))
      
      if (!updated.some(el => el))
      {
        this.addOrUpdateMember(this.findQueue(0), msg.exten, diff)
      }
    },
    
    extenStatusChanged(msg)
    {
      const q0 = this.findQueue(0)
      if (msg.status=="unavailable")
      {
        this.deleteMember(q0, msg.exten)
      }
      // остальные статусы впишет link или dial
      // здесь мы только отслеживаем появление и исчезновение оператора
      else if (msg.status=="not-in-use")
      {
        if (this.queueOfExten(msg.exten)) return // Номер уже есть в какой-то очереди, в нулевую добавлять не нужно
        // Номер, возможно, в очереди 0, а может и нет. В последнем случае его надо туда добавить
        this.addOrUpdateMember(q0, msg.exten, { status: msg.status, caller: null })
      }
    },
    
    workerByExten(ext)
    {
      const rec = this.$store.state.preload['/worker'].find(el => el.sip == ext)
      return rec ? rec.entity : undefined
    },
    
    isExtenInQueue(q, ext)
    {
      for (const m of q.members)
      {
        if (ext==m.exten) return true
      }
      
      return false
    },

    queueOfExten(ext)
    {
      for (const q of this.queues.filter(el => el.queue != 0))
      {
        if (this.isExtenInQueue(q, ext)) return q
      }
      
      return undefined
    },
    
  }
}

</script>