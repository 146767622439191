'use strict';

import { store } from "store.js"
import $ from "jquery"
import _ from "underscore"
import moment from "moment"
import permission from "permission"
import preload from "preload"
import html2canvas from  "html2canvas"
import canvas2blob from "canvas2blob"
import bsNotify from "bootstrap-notify"
import { saveAs } from 'file-saver'

const cooperMacroText = require("text-loader!common/misc/cooper.tpl")

    export const errorPoolDesc = {
      nomoney: { text: "Нет денег на счету", ico: "fa-money-bill", nas_name_pool: 'error-no-money' },
      unknown_device: { text: "Неизвестное устройство", ico: "fa-server", nas_name_pool: 'error-unknown-device' },
      unknown_user: { text: "Неизвестный пользователь", ico: "fa-user-secret", nas_name_pool: 'error-unknown-user' },
      unknown_mac: { text: "Неизвестный MAC-адрес", ico: "fa-desktop", nas_name_pool: 'error-unknown-mac' },
      disabled_user: { text: "Пользователь отключен", ico: "fa-exclamation-circle", nas_name_pool: 'error-disabled-user' },
      sessions_limit: { text: "Сессия сверх лимита", ico: "fa-stack-overflow", nas_name_pool: 'error-sessions-limit' },
      unknown_credentials: {text: "Неправильный логин/пароль", ico: "fa-lock", nas_name_pool: "error-unknown-credentials"},
    }
    
    export function parseParams(query)
    {
      query = query || "";
      query = query.replace("?","");
      if (!query) return {};

      var list = query.split("&");
      var p = {};

      $.each(list, function(i,el)
      {
        var param = el.split("=");
        var key = param.shift();
        var rest = param.join('=');
        p[key] = rest;
      });

      return p;
    }

    export function URLToState(url)
    {
      var s = url.split("?");
      var path = s[0];
      var query= s[1];

      var params = parseParams(query);
      var m = path.match(/^(\/)?([\w-]+)(.*)/);
      params._page = m[2];
      params._rest = m[3] || "";

      return params;
    }

    export function stateToURL(state)
    {
      var p = _.chain(state)
        .map(function(value,key) { return encodeURIComponent(key) + "=" + encodeURIComponent(value); })
        .filter(function(el) { return el.substr(0,1) !== "_"; })
        .join("&");
      if (p) p = "?"+p;
      state._rest = state._rest || "";

      return "/" + state._page + state._rest + p;
    }

    export function encodeURLParams(params)
    {
      var fragment = "";

      var p = _.chain(params)
        .map((value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}` )
        .filter((el) => el.substr(0,1) !== "_" )
        .join("&");

      if (p) {
        fragment += "?" + p;
      }

      return fragment;
    }

    export function filterObject(state, cond)
    {
      var set = {};
      _.each(state, function(val, key) {
        if (cond(key)) {
          set[key] = val;
        }
      });

      return set;
    }

    export function filterState(state, prefix)
    {
      var re = new RegExp("^" + prefix + "_");
      return filterObject(state, function(key) { return key.match(re); });
    }
    
    const notifyColors = {
      "success": "darkgreen",
      "warning": "rgb(255, 240, 106)",
      "error": "darkred",
    }

    export function notify(text, options={type: "success"})
    {
      options = options || {};
      const type = options.type=="error" ? "danger" : options.type
      
      const args = {
          message: text,
      }
      
      const params = {
        type,
        delay: options.delay || 15000,
        placement: { from: "top", align: "right" },
        z_index: 5050,
      }

      if (options.title) args.title = options.title
      
      params.template = `<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert" 
        style="border-width: 0px; border-left: 15px solid ${notifyColors[options.type]}; box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.3);">
        <button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>
        <span data-notify="icon"></span>
        <span data-notify="title" style="font-size: larger">{1}</span>
        <span data-notify="message">{2}</span>
        <div class="progress" data-notify="progressbar">
          <div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>
        </div>
        <a href="{3}" target="{4}" data-notify="url"></a>
        </div>`

      params.animate = {
        enter: 'animated fadeInRight',
        exit: 'animated fadeOutRight',
      }

      $.notify(args, params)
    }
    
    export function warn(text)
    {
      notify("", {title: text, type: "warning"})
    }

    export function notifyBottom(text, options)
    {
      options = options || {}
      
      $.notify({
          icon: options.icon,
          message: text
        },
        {
          type: "info",
          delay: options.delay || 10000,
          placement: { from: "bottom", align: "right" },
          z_index: 5050,
        });
    }

    export function notifyError(err)
    {
      const text = err instanceof Error ? err.stack : err
      notify(text, { type: "error"} );
    }

    export function notifyDeviceLoopDetect (message) {
      $.notify({
        icon: 'glyphicon glyphicon-warning-sign',
        message: message
      },
      {
        type: 'danger',
        delay: 0,
        placement: { from: "top", align: "right" },
        z_index: 5050,
        onShow:  () => { favicon_badge++; favicon.badge(favicon_badge); },
        onClose: () => { favicon_badge--; favicon.badge(favicon_badge); }
      });
    }

    export function notifyHttpError(res)
    {
      let text
      if ("responseText" in res)
      {
        text = res.responseText;
        if (res.responseJSON) text = res.responseJSON.text_ru || res.responseJSON.text
        text = "Ошибка "+res.status+": "+text
      }
      else
      {
        text = res
      }
      notify(text, { type: "error"} );
      console.log(res)
    }

    export function alertDiv(text, options){
      options = options || {};

      var type = options.type || "success";
      var typeHash = {
        "error": 	  { icon: 'glyphicon glyphicon-exclamation-sign', type: "danger"},
        "success":  { icon: 'glyphicon glyphicon-check',  type: "success"},
      };

      var div = $("<div />", {
        class: "text-center  alert alert-"+typeHash[type].type,
        role: "alert"
      });

      var glyphicon = $("<span />", {
        class: typeHash[type].icon,
        "aria-hidden": "true"
      });

      var h3 = $("<h3 />").append(glyphicon).append("&nbsp;").append("Ошибка");
      var h3text = $("<h3 />").append(text);

      div.append(h3).append(h3text);

      return div;
    }

    export function formatSize(size)
    {
      var kb = 1024, mb = 1024 * kb, gb = 1024 * mb, tb = 1024 * gb;
      if (size === "0")  { return "0"; }
      else if (size < kb) { return (size + " б"); }
      else if (size < mb) { return ((size / kb).toFixed(2) + " Кб"); }
      else if (size < gb) { return ((size / mb).toFixed(2) + " Мб"); }
      else if (size < tb) { return ((size / gb).toFixed(2) + " Гб"); }
      else { return (size / tb).toFixed(2) + " Тб"; }
    }

    export function formatHexSize(size)
    {
      return formatSize(parseInt(size, 16));
    }

    export function formatUptimeFromStart(datetime, withoutSeconds)
    {
      var a = moment();
      var b = moment(datetime);
      return formatUptime( a.diff(b, 'seconds'), withoutSeconds ); // 86400000
    }

    export function formatUptime(seconds, withoutSeconds, withoutHours)
    {
      var numdays = Math.floor(seconds / 86400);
      var numhours = Math.floor((seconds % 86400) / 3600);
      var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
      var numseconds = Math.floor((seconds  % 3600) % 60 );

      var res = ''
      
      if (!withoutHours) {
        res = zeroPad(numhours,2) + ":"
      }

      res += zeroPad(numminutes,2);

      if (!withoutSeconds) {
        res += ":" + zeroPad(numseconds, 2);
      }

      if (numdays) {
        res = numdays + "д " + res;
      }

      return res;
    }

    export function formatLocalTime(t,f)
    {
      return t ? moment(t, "YYYY-MM-DD HH:mm:ssZ").format(f || "YYYY-MM-DD HH:mm") : "-";
    }

    export function unixTime (t) {
      return moment(t, "YYYY-MM-DD HH:mm:ssZ").format("x");
    }

    export function fromUnixtime (t) {
      return t ? moment.unix(t).format('YYYY-MM-DD HH:mm:ss') : '-';
    }

    export function formatMap(map, val)
    {
      map = map || {};
      return val in map ? map[val] : val;
    }

    export function formatMapColl(map, val, f="entity")
    {
      if (!val) return "-"
      var m = map.findWhere({ [f]: val.toString() }) || map.findWhere({[f]: +val})
      return m ? m.get("name") || m.get("name_ru") : val
    }

    export function formatMapArray(map, val) {
      if (!val) return ''
      var m = _.findWhere(map, {entity: val}) || _.findWhere(map, {entity: +val})
      return m ? m.name || m.name_ru : val
    }

    export function formatMoney(sum)
    {
      if (_.isNull(sum)) return "-";
      return parseFloat(sum).toFixed(2) + "&thinsp;" + (config.main_currency || "грн");
    }

    export function formatCurrency(currList,sum, onlyNum)
    {
      if (_.isNull(sum)) return "-";
      var curr = currList.findWhere({entity: userSettings.currency}) || currList.findWhere({main: 1});
      if (!curr) throw Error("formatCurrency: don't know which currency to use");

      var res = (parseFloat(sum)*curr.get("rate")).toFixed(2);

      if (onlyNum) {
        return res;
      }

      res += "&thinsp;" + curr.get("short_name_ru");
      return res;
    }

    export function formatCurrency2(currList,sum, onlyNum)
    {
      if (_.isNull(sum) || !currList) return "-";
      var curr = currList.find(el => el.entity==userSettings.currency) || currList.find(el => el.main==1)
      if (!curr) return "?"

      var res = (parseFloat(sum)*curr.rate).toFixed(2);

      if (onlyNum) {
        return res;
      }

      res += " " + curr.short_name_ru // здесь не пробел, а &thinsp;
      return res;
    }

    export function formatCurrencyForAPI(currList,sum)
    {
        if (_.isNull(sum)) return "-";
        var curr = currList.findWhere({entity: userSettings.currency}) || currList.findWhere({main: 1});
        if (!curr) throw Error("formatCurrency: Do not know what currency to use");

        return (parseFloat(sum) / curr.get("rate"));
    }

    export function toMainCurrency(sum)
    {
        if (_.isNull(sum) || _.isUndefined(sum)) return "-";
        const list = store.state.preload['/currency']
        if (!list.length) return "-"
        const userCurr = store.state.userSettings.currency
        var curr = list.find(el => el.entity==userCurr) || list.find(el => el.main==1)
        if (!curr) throw Error("formatCurrency: Do not know what currency to use")

        return parseFloat(sum) / curr.rate
    }

    export function fromMainCurrency(sum)
    {
        if (_.isNull(sum) || _.isUndefined(sum)) return "-";
        const list = store.state.preload['/currency']
        if (!list.length) return "-"
        const userCurr = store.state.userSettings.currency
        var curr = list.find(el => el.entity==userCurr) || list.find(el => el.main==1)
        if (!curr) throw Error("formatCurrency: Do not know what currency to use")

        return formatFixed(parseFloat(sum) * curr.rate, 2)
    }

    export function formatFixed(sum,digits)
    {
      return parseFloat(sum).toFixed(digits)
    }

    export function zeroPad(number, digits)
    {
      var num = number + "";
      while (num.length < digits) {
        num = '0' + num;
      }
      return num;
    }

    export function when(promises)
    {
      return $.when(...promises);
    }

    export function renderFromTemplate(el, tpl, data)
    {
      data = data || {};
      data.common = this;
      var s = _.template(tpl, null, {variable: "me"})(data);
      el.empty().append(s);
    }

    export function renderTemplate(template, data) {
      data = data || {};
      var rendered = _.template(template, null, {variable: 'me' })(data);
      return rendered;
    }

    export function htmlBr (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    }

    export function findKeyWhere(obj, predicate, context)
    {
      var result = null;
      _.any(obj, function(value, index, list) {
        if (predicate.call(context, value, index, list)) {
          result = index;
          return true;
        }
      });
      return result;
    }

    export function diffStream()
    {
      return {
        prev: null,
        next(n) {
          var res = null;

          if (!_.isNull(this.prev)) {
            res = n - this.prev;
          }

          this.prev = n;
          return res;
        }
      };
    }

    export function serializeForm(form)
    {
      var data = $(form).serializeArray();
      return  _.object( _.pluck(data,"name"), _.pluck(data,"value") );
    }

    export function Macro(text)
    {
      var o = {};
      var globals = {};

      var list = _.filter(text.split(/###\s+/),el => el!="");

      list.forEach(el => {
        var a = el.split(/\n/);
        var name = a.shift();
        var body = a.join("\n");
        var fun = _.template(body,null,{variable: "o"});
        o[name] = function(args) { return fun(_.extend({}, globals,args)) };
      });

      o.setGlobal = function(name,value)
      {
        globals[name] = value;
      };

      o.setGlobal("uniqueId", (prefix) => _.uniqueId(prefix));
      o.setGlobal("perm", permission);
      o.setGlobal("preload", preload);
      return o;
    }

    export function populateSelect(el, coll, current)
    {
      el = $(el);
      el.empty();
      coll.each(m => {
        let option = $("<option/>", {
          value: m.get("entity"),
          text: m.get("display_name") || m.get("text")
        });

        if (current && m.get("entity") == current) {
          option.attr('selected', 'selected');
        }

        el.append(option);
      });
    }

    export function okPromise(result)
    {
      return $.Deferred().resolve(result).promise();
    }

    export function failPromise(result)
    {
      return $.Deferred().reject(result).promise();
    }

    export function formatErrorPoolDesc(pool)
    {
      return errorPoolDesc[pool] || "";
    }
    
    export function formatMac(mac)
    {
      return mac ? zeroPad(mac,12).match(/../g).join(":") : "-";
    }
    
    export function genRandom(cnt)
    {
      return Math.random().toString(36).slice(-cnt);
    }
    
    export function genRandomNonAmbLc(cnt)
    {
      return _.sample("abcdefghijkmnprstwxyz23456789".split(""), cnt).join("");
    }
    
    export function genRandomNonAmb(cnt)
    {
      return _.sample("ACEFHJKLMNPRTUVWXY23456789".split(""), cnt).join("");
    }

    export function genRandomDigits(cnt)
    {
      return Math.random().toString(10).slice(-cnt);
    }

    export function printScreen($el)
    {
      $el = $el || $(document)
      html2canvas($el).then(canvas =>
      {
        canvas.toBlob(function(blob)
        {
          saveAs(blob, 'screenshot_'+moment().format('YYYY-MM-DD_HH:mm')+'.png')
        })
      })
    }
    
    export function formatNumber(num)
    {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u2009")
    }

    export function formatLimitedStr (str, length) {
      if (str == null) {
        return "";
      }

      if (str.length <= length) {
        return str;
      }

      str = str.substring(0, length);
      return str + '&hellip;';
    }
    
    export function hashCode(str)
    {
      var hash = 0;
      for (var i = 0; i < str.length; i++) 
      {
        var char = str.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
      }
      
      return Math.abs(hash);
    }
  
    export const cooperMacro = Macro(cooperMacroText)

    export function animate(el,klass)
    {
        $(el).removeClass(klass)
             .addClass(klass)
             .delay(1000)
             .queue(function()
             {
                $(this).removeClass(klass).dequeue();
            })
    }
    
    export const dedup = {
      seen: {},
      send(key, func)
      {
        Object.keys(this.seen).forEach(k =>
        {
          if (Date.now()-this.seen[k] > 5*1000) delete this.seen[k]
        })
        
        if (!this.seen[key])
        {
          this.seen[key] = Date.now()
          func()
        }
      }
    }

    export function ifMatch(str, ...patterns)
    {
      for (const p of patterns)
      {
        if (p.test(str)) return true
      }
      
      return false
    }

   // https://stackoverflow.com/questions/65732144/vue-js-3-replace-update-reactive-object-without-losing-reactivity
   export function deepAssign(dest, src)
   {
  if (typeof src === 'object') 
  {
    for (const k in src) 
    {
       if (src[k] === null) 
       {
         dest[k] = null
       } 
       else if (Array.isArray(src)) 
       {
         for (let i = 0; i < src.length; ++i) 
         {
           if (src[i] === null) 
           {
             dest[i] = null
           } 
           else if (Array.isArray(src[i]) || typeof src[i] === 'object') 
           {
             deepAssign(dest[i], src[i]);
           } 
           else 
           {
             dest[i] = src[i]
           }
         }
       }
       else if (Array.isArray(src[k]) || typeof src[k] === 'object') 
       {
         dest[k] = {}
         deepAssign(dest[k], src[k])
       } 
       else 
       {
         dest[k] = src[k]
       }
    }
  }
   }
    