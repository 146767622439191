<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>

import CallsByDate from "calls/page/bydate.vue"
import CallsTable from "calls/page/table.vue"
import StdPage from "common/visual/StdPage.vue"

import { markRaw } from "vue"

export default {

  name: "CallsPage",
  
  components: {CallsByDate, CallsTable},
  
  mixins: [StdPage],
  
  data()
  {
    return  {
      overlays: [
        {
          re: /\/calls$/,
          component: markRaw(CallsTable),
        },
        {
          re: /(?:)/,
          component: markRaw(CallsByDate),
        },
      ],
    }
  },
  
  methods: {
    pathToState(parts)
    {
      return {
        _rest: parts[2],
      }
    }
  },
  
}
</script>
