define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common',
  'navigation',
  'sysconf',
  'preload',

  'common/table/tiny-table-view',
  'common/visual/visual',
                
  'text-loader!client/iptv/session/layout.tpl',
  'text-loader!client/iptv/session/row.tpl',
  'text-loader!client/iptv/session/table.tpl'

], function ($, _, Backbone, darsan, common, navigation, sysconf, preload,
             TableView, visual, listTemplate, rowTemplate, tableTemplate) {

  return Object.create(visual).extend({

    create: function (el, opt) {
      var me = this;

      visual.create.apply(me, arguments);
      me.renderFromTemplate(listTemplate, {});

      var SessionModel = Backbone.Model.extend({});

      var SessionList  = Backbone.CollectionEx.extend({
        model: SessionModel
      });

      me.collection = new SessionList();

      me.table  = new TableView({
        el:            me.$el.find('div.table-responsive'),
        collection:    me.collection,
        rowTemplate:   rowTemplate,
        tableTemplate: tableTemplate,
      });
      
      return preload.ensure("iptv-channel")
    },

    setState: async function (state) 
    {
      var me = this;

      if (me.state && me.state.uid == state.uid) return;
      me.state = state;

      const domain = sysconf.domainByUid(state.uid) || config.domain;
      me.collection.url = darsan.makeUrl(domain, 'client', '/client/' + state.uid + '/iptv/session/current');
      await me.collection.fetch({ reset: true })

      const channels = preload.get(domain, "iptv-channel")
      me.collection.forEach(child => 
      {
        child.set("name", channels.find("no", child.get("channel"))?.get("name_ru"))
      })

      me.table.render()
    },

    reload: async function () {
      await this.collection.fetch({ reset: true });
      this.table.render()
    }
  });
});