<template>
  <div v-if="ready && loaded">
    <div  class="panel-info" v-if="info">
      <h5 class="panel-title">Последняя авторизация: {{time}}</h5>
      <table class="table table-bordered">
        <tbody>
            <tr>
              <td class="table-td">Канал:</td>
              <td>
                {{channel}}
              </td>        
            </tr>
            <tr>
              <td class="table-td">Основание:</td>
              <td>
                {{info.reason}}
              </td>        
            </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h5 class="info-tv-text">Не было авторизаций в течение суток</h5>
    </div>
  </div>
</template>

<script>
import darsan from "darsan"
import {formatLocalTime} from "common"

export default {

  name: "LastTvAuth",
  
  props: {
    state: Object,
  },
  
  data() 
  {
    return {
      ready: false,
      loaded: false,
      info: {},
    }
  },
  
  created()
  {
    this.ensure(["iptv-channel"])
      .then(() => this.ready = true)
  },
  
  methods: {
    async load()
    {
      this.loaded = false
      this.info = null
      try 
      {
        this.info = await darsan.getm("", "iptv", `/client/${this.state.uid}/last-auth`)
        this.loaded = true
      }
      catch(err)
      {
        if (err.status==410)
        {
          this.loaded = true
          this.info = null
        }
        else
        {
          darsan.error(err)
        }
      }
    },
  },
  
  computed: {
    time()
    {
      return formatLocalTime(this.info.time)
    },
    channel()
    {
      const list = this.$store.state.preload['/iptv-channel']
      const ch = list.find(el => el.no == this.info.channel)
      return ch ? ch.name_ru : this.info.channel
    }
  },
  
  watch: {
    state: {
      handler()
      {
        this.load()
      },
      immediate: true,
    }
  },
}
</script>

<style scoped>
  .panel-title {
    margin-left: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .info-tv-text {
    margin-left: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  table {
    margin-bottom: 0;
  }

  .panel-info {
    margin-top: 10px;
  }

  .table-td {
    width: 30%;
  }
</style>