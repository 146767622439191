<template>
<div>
  <form class="form-horizontal">
    <Select :options=periodOptions v-model="period" label="Период" :labelSize=5 :size=3 />
    <br/>
  </form>
  <div style="overflow: hidden;text-align: center" class="hcdiv">
    <DeviceGraph type="area" title="Загрузка ЦП" yAxisTitle="%" :series0=series0 :values0=cpu />
  </div>
</div>
</template>

<script>
import Select from "common/visual/input/Select.vue"
import DeviceGraph from "device/status/modules/DeviceGraph.vue"
import Icon from "common/visual/Icon.vue"
import darsan from "darsan"
import radio from "radio"

export default {

  name: "GeneralBoard",

  components: {Select, DeviceGraph, Icon},
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      period: "day2",
      
      periodOptions:  [
        { value: 'hour6', text: '6 часов' },
        { value: 'hour12', text: '12 часов' },
        { value: 'day', text: 'Сутки' },
        { value: 'day2', text: 'Двое суток' },
        { value: 'week', text: 'Неделя' },
        { value: 'month', text: 'Месяц' },
      ],
      
      dialogOpened: true,
      
      series0: {
         name: "CPU load",
         color: "blue",
         tooltip: {
           valueDecimals: 1,
           valueSuffix: " %",
         },
       },
       
       cpu: {
         start: 0,
         step: 0,
         data: [],
       },
       
    }
  },
  
  created()
  {
    const me = this
    radio.channel("device:ui").on("dialog-opened", function(val) { me.dialogOpened = val})
  },
  
  methods: {
    async loadValues()
    {
      if (!this.state.device) return
      const data = await darsan.get("", "device", `/${this.state.type}/${this.state.device}/data/general?period=${this.period}`)
      this.cpu.start = data.start
      this.cpu.step = data.step
      this.cpu.data = data.series.cpu
    },
    
  },
  
  watch: {
    state:
    {
      deep: true,
      handler(val) { this.loadValues() },
    },
    period:
    {
      handler(val) { this.loadValues() },
      immediate: false,
    },
  },

}
</script>

<style>
.hcdiv .highcharts-container {
  display: inline-block !important;
}
</style>

