<template>
  <span>
    <span v-if=long @click=showFullDiff class="click" >
      <StrDiff :oldStr="safeStr(oldStr).substr(0,100)" :newStr="safeStr(newStr).substr(0,100)" />
      &nbsp;&#x2026;
    </span>
    <StrDiff v-else :oldStr="safeStr(oldStr)" :newStr="safeStr(newStr)" />
  </span>
</template>

<script>
import StrDiff from "common/visual/history/StrDiff.vue"
import * as dialog from "common/dialog-bs/dialog"

export default {
  name: "AttrDiff",
  
  components: {StrDiff},
  
  props: {
    long: Boolean,
    oldStr: null,
    newStr: null,
  },
  
  methods: {
    async showFullDiff()
    {
      await dialog.showComponent("common/visual/history/ModalDiff", "Изменения", { oldStr: this.oldStr, newStr: this.newStr })
    },

    safeStr(s)
    {
      if (!s) return ""
      return String(s)
    },
  },
}
</script>

<style scoped>
.click {
  cursor: pointer;
}
</style>