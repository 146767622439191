<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0" >
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize" >{{label}}</label>
      <div :class="'col-sm-'+size">
          <select class="form-control" :name=name :id=uniq @change='$emit("update:modelValue", $event.target.value)' >
            <option v-if="withBlank" value="" :selected="modelValue==''" ></option>
            <option v-for="o in options" :key="o[valueName]" :value="o[valueName]" :selected="o[valueName]==modelValue" >{{o[textName]}}</option>
          </select>
      <span class="help-block">{{error}}</span>
    </div>
    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>
  </div>
</template>

<script>
import _ from "underscore"
import $ from "jquery"
import Icon from "common/visual/Icon.vue"

export default {
  name: "Select",
  
  components: {Icon},
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
      default(rawProps)
      {
        return []
      }
    },
    size: {
      type: Number,
      default: 6,
    },
    modelValue: {
      type: String,
    },
    labelSize: {
      type: Number,
      default: 3,
    },
     error: {
      type: String,
    },
    // Добавить пустой вариант ""
    withBlank: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
    },
    textName: {
      type: String,
      default: "text",
    },
    valueName: {
      type: String,
      default: "value",
    },
 },
 
 emits: [ 'update:modelValue' ],
  
  data()
  {
    return {
      uniq: null,
    }
  },
  
  mounted()
  {
    this.uniq = _.uniqueId("select")
    this.init()
  },

  activated()
  {
    this.init()
  },
  
  methods: {
    init()
    {
      const shouldInit = this.modelValue===undefined || (this.modelValue=="" && !this.withBlank)
      if (shouldInit && this.options.length)
      {
        const v = this.options[0][this.valueName]
        $(this.$el).find("select").val(v)
        this.$emit("update:modelValue", typeof v == "number" ? String(v) : v)
      }
    }
  },
}
</script>
