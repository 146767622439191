<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0">
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>

    <div :class="'col-sm-'+size">
      <input class="form-control" :list="'list' + uniq" :name=name :id=uniq :value=modelValue :maxlength=maxlength :type=type
      @change='$emit("update:modelValue", $event.target.value)' />
      <datalist v-if="datalist" :id="'list' + uniq">
        <option v-for="option in datalist" :value=option.value>{{option.text}}</option>
      </datalist>
      <span class="help-block">{{error}}</span>
    </div>
    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>

  </div>
</template>

<script>

import _ from "underscore"

export default {
  name: "Input",
  
  data()
  {
    return { 
       uniq: null,
    }
  },
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    size: {
      type: Number,
      default: 6,
    },
    modelValue: {
       type: String,
    },
    labelSize: {
      type: Number,
      default: 3,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    error: {
      type: String,
    },
    info: {
      type: String,
    },
    datalist: {
      type: Array,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  
  mounted()
  {
    this.uniq = _.uniqueId("input")
  },
  
}
</script>

<style scoped>
  input::-webkit-calendar-picker-indicator {
    display: none !important;
  }
</style>
