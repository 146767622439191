<template>
<div role="tabpanel">
  <ul class="nav nav-tabs" role="tablist">
    
    <li class="nav-item" role="presentation" v-for="t in visibleTabs">
      <a class="nav-link" :href="'#'+t.name" role="tab" data-toggle="tab" data-direct-link=true >
          <Icon :name=t.icon />&nbsp; 
          <template v-if="t.subpath==''"><b>{{infoTabTitle || t.text}}</b></template>
          <template v-else>{{t.text}}</template>
      </a>
    </li>

    <li class="dropdown" role="presentation" v-if="restTabs.length" >
      <a data-toggle="dropdown" class="dropdown-toggle" data-target="#" style='border-bottom-color:transparent;cursor:pointer;'>
        Ещё <b class="caret"></b>
      </a>
      <ul class="dropdown-menu" style='border-top-color: transparent'>
        <li class="nav-item" v-for="r in restTabs">
          <a class="nav-link" :href="'#'+r.name" role="tab" data-toggle="tab" data-direct-link=true >
          <Icon :name=r.icon />&nbsp; {{r.text}}
	  </a>
	 </li>
      </ul>
    </li>
  
  </ul>
  
  <div class="tab-content" style="padding-top: 8px;">
    <div role="tabpanel" class="tab-panel">
      <keep-alive>
        <component :is=currentTab :state=state />
      </keep-alive>
    </div>  
  </div>
</div>
</template>

<script>

import $ from "jquery"
import * as allowed from "allowed"
import {notifyError,okPromise} from "common"
import _ from "underscore"
import {promiseLoadModule, changeURLSubpath} from "navigation"
import RadixModule from "RadixModule.vue"
import { markRaw } from "vue"

  export default {
    name: "Tabs",
    
    props: {
      state: Object,
      infoTabTitle: String,
      tabs: Array,
      uiList: String,
    },
    
    data()
    {
      return {
        currentTab: null,
      }
    },
    
    mounted()
    {
      $('a[data-toggle="tab"]').on('shown.bs.tab', e => 
      {
        const name = e.target.getAttribute("href")
        this.showTab(name.substring(1))
      })

      this.showTabByLink(this.state._rest)
    },
    
    activated()
    {
    /*
      const active = this.$el.querySelector(".active")
      if (!active) return

      const activeLi = active.classList.contains("dropdown") ? active.querySelector("li.active") : active
      const name = activeLi.getElementsByTagName("a")[0].getAttribute("href").substr(1)
      const rec = this.tabs.find(el => el.name==name)
      if (!rec) return

      changeURLSubpath(rec.subpath)
     */
    },
    
    methods: {
      showTab(name)
      {
        const rec = this.tabs.find(el => el.name==name)
        if (!rec)
        {
          notifyError("Нет вкладки с именем "+name)
          return
        }
        
        loadTabComponent(this, rec.modPath)
        .then(mod => 
        {
          this.currentTab = markRaw(mod)
          if (this.state.subpath!=rec.subpath) changeURLSubpath(rec.subpath)
         })
        .catch(err => { console.log(err); notifyError(err) })
      },
      
      showTabByLink(link)
      {
        let subpath
        if (link=="" || link=="/") 
        {
          subpath = ""
        }
        else
        {
          const m = link.match(/\/([\w-]+)/)
          subpath = m[1]
        }

        let item = this.tabs.find(el => el.subpath==subpath)
        if (!item) item = this.tabs.find(el => el.subpath=="")
        
        const name = item.name
        $(this.$el).find(`a[href='#${name}']`).tab("show")
      },
    },
    
    computed: {
      allowedTabs()
      {
        const tabs = allowed.filterLayout(this.tabs)

        if (this.uiList)
        {
          const visibleTabs = []

          for (const name of this.uiList.split(","))
          {
            const i = tabs.findIndex(el => el.name==name)
            if (i != -1)
            {
              visibleTabs.push(tabs[i])
              tabs[i].visible = true
            }
          }

          const tabs2 = [].concat(visibleTabs, tabs.filter(el => !el.visible))
          return tabs2
        }
        else
        {
          for (const el of tabs)
          {
            el.visible = el.defaultTab
          }
          
          return tabs
        }
      },
      
      visibleTabs()
      {
        return this.allowedTabs.filter(el => el.visible)
      },
      
      restTabs()
      {
        return this.allowedTabs.filter(el => !el.visible)
      },
    },
    
  }
  
function loadTabComponent(me, path)
{
  const compName = toCamelCase(path)
  const comp = me.$app.component(compName)

  if (comp) return okPromise(comp)
  
  return promiseLoadModule(path).then(function(mod)
  {
    if (isOldModule(mod))
    {
      const tpl = {
        name: compName,
        components: {RadixModule},
        props: {state: Object},
        template: `<RadixModule path="${path}" :state=state />`,
      }
      const comp = me.$app.component(compName, tpl)
    }
    else
    {
      const comp = me.$app.component(compName, mod)
    }

    return me.$app.component(compName)
  }).catch((err) =>
  {
    notifyError(err)
    console.log(err)
  });
  
}

function toCamelCase(str)
{
  return str.split('/').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join('')
}

function isOldModule(mod)
{
  return mod.setState
}
    
</script>
