<script>

import radio from "radio"

export default {

  data () {
    return {
      currentComponent: null,
      state: {
        _rest: "",
        _tail: "",
        subpath: "",
        id: "",
      },
    }
  },
  
  created () {
    radio.channel("main").on("routeChanged", ({ path, params }) => this.setState(path, params))
  },
  
  methods: {
    pathToState(parts)
    {
      return {
        _rest: "/" + parts.slice(3).join("/"),
        id: parts[2],
        subpath: parts[3],
        _tail: parts[4]
      }
    },
    
    setState(path, params) {
      for (const el of (this.overlays || []))
      {
//      console.log(path, el.re, path.match(el.re))
        if (path.match(el.re))
        {
          this.currentComponent = el.component
          break
        }
      }

      const parts = path.split("/")
      const state = this.pathToState(parts)

      state.params = params
      for (const p of new URLSearchParams(params)) {
        state[ p[0] ] = p[1]
      }
      
      this.state = state
    },

  },
  
}

</script>
