<template>
<div>
  <h4>Групповая смена ФОП</h4>
  <div class="form-group">
    <label for="fop">Новый ФОП:</label>
    <select class="form-control" id="fop" v-model="fop">
      <option v-for="item in $store.state.preload['/fop']" :value=item.entity >{{item.display_name}}</option>
    </select>
  </div>

  <br>
  <button class="btn btn-secondary" @click=makeOp >Изменить ФОП</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-change-fop",
    
    props: {
      query: String,
    },
    
    emits: [ "dismiss" ],

    data()
    {
      return {
        fop: "",
      }
    },
    
    created()
    {
      this.ensure("fop")
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/client/fop', {
            fop: this.fop,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))

        this.$emit("dismiss")
      }
    },
  }
</script>