<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>

import ClientNew from "client/new/ClientNew.vue"
import ClientTable from "client/page/ClientTable.vue"
import ClientPanel from "client/page/ClientPanel.vue"

import StdPage from "common/visual/StdPage.vue"

import {markRaw} from "vue"

export default {
  name: "ClientPage",
  title: "Абоненты",
  mixins: [StdPage],
  
  data()
  {
    return {
      overlays: [
        {
          re: /\/client\/\d+/,
          component: markRaw(ClientPanel),
        },
        {
          re: /\/client\/!new/,
          component: markRaw(ClientNew),
        },
        {
          re: /(?:)/,
          component: markRaw(ClientTable),
        },
      ],
    }  
  },
  
}

</script>
