<template>
  <Panel title="Подключенные каналы ТВ" icon="fa-tv">
    <IptvChannels :state=state />
    <LastTvAuth :state=state />
  </Panel>
  <Panel title="Завершенные сессии" icon=star :toolbar=sessToolbar @toolbar-click=sessClick >
    <RadixModule path="client/iptv/stat/iptv-stat" :state=state ref=sessions />
  </Panel>
</template>

<script>
import IptvChannels from "client/iptv/channels/Channels.vue"
import RadixModule from "RadixModule.vue"
import Panel from "common/visual/Panel.vue"
import LastTvAuth from "client/iptv/LastTvAuth.vue"

export default {
  components: {IptvChannels, RadixModule, Panel, LastTvAuth},
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      sessToolbar: [
        {
          name: "1",
          text: "Сутки",
        },
        {
          name: "2",
          text: "2 cуток",
        },
        {
          name: "7",
          text: "Неделя",
        },
      ]
    }
  },
  
  methods: {
    sessClick({from})
    {
      this.$refs.sessions.invoke("fetch", from)
    },
  },
}
</script>
