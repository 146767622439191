<template>
  <l-map style="height: 60vh; width: 60vh; z-index: 9" ref='myMap'
    :zoom="zoom"
    :center="center"
    :options="{preferCanvas: true}"
  >
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker :lat-lng="[state.latitude, state.longitude]"></l-marker>
  </l-map>
</template>

<script>
import L from 'leaflet'
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet/dist/vue-leaflet.es.js'

export default {

  name: "GeoMap",
  
  components: {LMap, LTileLayer, LMarker},
  
  mounted() 
  {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
      iconUrl: require('leaflet/dist/images/marker-icon.png').default,
      shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
    });
    
    this.$nextTick(() => {
      this.center = [this.state.latitude, this.state.longitude]
      setTimeout(() => this.$refs.myMap.mapObject.invalidateSize(), 1000)
    })
  },
  
  data()
  {
    return {
      state: {},
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: this.$store.state.userSettings.sessionMapZoom || 6,
      center: [0,0],
      bounds: null,
      saveCenter: null,
    }
  },
 
}
</script>
