<template>
  <HistorySearch name="history" :showAdmin=showAdmin :showClient=showClient :showEntity=showEntity :apiPath=apiPath
   :state="state.params" :title=title :paged=true
  />
</template>

<script>
import HistorySearch from "common/visual/history/HistorySearch.vue"
import StdPage from "common/visual/StdPage.vue"

export default {
  name: "HistoryPage",
  components: {HistorySearch},
  mixins: [StdPage],
  
  data()
  {
    return {
    }
  },

  computed: {
    queryType()
    {
      if (!this.state.id) return "history"
      if (this.state.id=="admin") return "admin"
      return "entity"
    },
    showAdmin()
    {
      return this.queryType != "admin"
    },
    showEntity()
    {
      return this.queryType != "entity"
    },
    showClient()
    {
      return this.queryType != "entity"
    },
    title()
    {
      if (this.queryType=="history") return "История"
      if (this.queryType=="entity") return `Изменения объекта /${this.state.id}/${this.state.subpath}`
      if (this.queryType=="admin") return `Действия работника ${this.state.subpath}`
      return "???"
    },
    apiPath()
    {
      if (this.queryType=="history") return "/history"
      if (this.queryType=="entity") return `/entity/${this.state.id}/${this.state.subpath}`
      if (this.queryType=="admin") return `/admin/${this.state.subpath}`
      return "???"
    }
  },
}
</script>
