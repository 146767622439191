<template>
<div id='container' class='container-fluid'>
<keep-alive>
<component :is=module :domain=domain />
</keep-alive>
</div>
</template>

<script>

import _ from "underscore"
import Backbone from "backbone"
import $ from "jquery"
import {promiseLoadModule, promiseCreateModule, setTitle} from "navigation"
import {notifyError, findKeyWhere} from "common"
import pageMixin from 'common/visual/page-mixin'
import radio from "radio"
import darsan from "darsan"
import * as allowed from "allowed"
import { markRaw, nextTick } from "vue"

export let currentPage=null 
let currentPageName=null, pageModules={}

export default {

  name: "page",
  
  data()
  {
    return {
      module: null,
      domain: "",
    }
  },
  
  created()
  {
    radio.channel("main").on("changePage", data => this.changePage(data))
  },
  
  methods: {
    changePage({url, opt})
    {
      var opt = opt || {};

      if (url[0]!="/") url = "/"+url;

      console.log("%cchangePage:", "color: green;",url);
      var path = pathFromURL(url);

      // проверяем, есть ли права смотреть эту страницу
      if (!allowed.allowed(path))
      {
        // выкидываем на 404
        path = pathFromURL("/error/404");
      }
      else
      {
        saveLastOpened(url);
      }

      currentPageName = path;
      if (opt.redirect) Backbone.history.navigate(url,{replace: true, silent: true})

      var pageChanged = currentPage != pageModules[path];

      if (pageChanged && currentPage)
      {
        //console.log(currentPage,"detaching...");
        if (isOldModule(currentPage)) currentPage.detach();
      }
      
      const u = new URL(window.location.origin+url)
      const dom = u.searchParams.getAll("domain")
      if (dom.length)
      {
        this.domain = dom[0]
      }
      else
      {
        this.domain = ""
      }

      if (pageModules[path])
      {
        loadOldPage(this, pageChanged, path, url, opt)
      }
      else
      {
        loadNewPage(this, path, url, opt)
      }
    }
  },
}

function loadOldPage(me, pageChanged, path, url, opt)
{
  if (pageChanged)
  {
    // страница сменилась
    currentPage = pageModules[path];
  
    const compName = path.replace(/\//g, "_").replace("_","")
    
    // console.log(me.currentPage,"attaching...");
    if (isOldModule(currentPage)) 
    {
      currentPage.attach();
    }

    me.module = markRaw(me.$app.component(compName))
    setTitle(currentPage.title)
  }

  // выгружаем верстку модуля на страницу
  if (isOldModule(currentPage)) currentPage.setURL(url);
  const u = new URL(window.location.origin+url)
  radio.channel("main").trigger("routeChanged", {path: u.pathname, params: u.search, fragment: window.location.hash})
}

function loadNewPage(me, path, url, opt)
{
  // подгружаем модуль и создаем его первый раз
  promiseLoadModule(path).then(function(mod)
  {
    if (isOldModule(mod))
    {
      mountNewPageOld(mod, me, path, url, opt)
    }
    else
    {
      mountNewPageVue(mod, me, path, url, opt)
      const u = new URL(window.location.origin+url)
      nextTick( () => radio.channel("main").trigger("routeChanged", {path: u.pathname, params: u.search, fragment: window.location.hash}) )
    }
  }).catch((err) =>
  {
    notifyError(err);
    console.log(err);
  });
}

function mountNewPageOld(mod, me, path, url, opt)
{
  console.log("loading page", path);
  // подгружаем модуль и создаем его первый раз
    _.extend(mod,pageMixin); // дополняем модуль свойствами страницы
    pageModules[path] = currentPage = mod

    const compName = path.replace(/\//g, "_").replace("_","")
    me.$app.component(compName, {
      template: "<div></div>",
    })

    me.module = markRaw(me.$app.component(compName))
    const cont = document.createElement("font")

    promiseCreateModule(mod,$(cont)).done(function()
    {
      me.$el.appendChild(cont)
      
      mod.pageName = path
      setTitle(mod.title)

      mod.setURL(url)
      mod.shown();
    }).catch((err) =>
    {
      notifyError(err);
      console.log(err);
    });
}

function mountNewPageVue(mod, me, path, url, opt)
{
  console.log("loading vue page", path);
  // подгружаем модуль и создаем его первый раз
  pageModules[path] = currentPage = mod

  const compName = path.replace(/\//g, "_").replace("_","")
  me.$app.component(compName, mod)

  me.module = markRaw(me.$app.component(compName))

  mod.pageName = path
  setTitle(mod.title)
  me.url = url
}

function pageFromURL(url)
{
  var res = url.match(/^(\/)?([\w-]+)/);
  if (!res) return null;
  return res[2];
}

function pathFromURL(url)
{
  var page = pageFromURL(url);
  return "/"+page+"/page/"+page+"-page"
}

function saveLastOpened(url)
{
  darsan.put("","darsan","/worker/"+(user.pretend||user.login)+"/config/radix/lastOpened", {value: encodeURI(url)})
}

function isOldModule(mod)
{
  return mod.setState
}

</script>

<style>
</style>
