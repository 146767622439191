<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>
import {markRaw} from "vue"

import StdPage from "common/visual/StdPage.vue"
import NasNew    from "nas/page/NasNew.vue"
import NasTable  from "nas/page/NasTable.vue"
import NasEditor from "nas/page/NasEditor.vue"

export default {
  name: "NasPage",
  title: "Серверы доступа",
  mixins: [StdPage],
  
  data () {
    return {
      overlays: [
        {
          re: /\/nas\/\d+/,
          component: markRaw(NasEditor),
        },
        {
          re: /\/nas\/!new/,
          component: markRaw(NasNew),
        },
        {
          re: /(?:)/,
          component: markRaw(NasTable),
        },
      ],
    }
  },
  
}

</script>
