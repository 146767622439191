<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0" >
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>

    <div :class="'col-sm-'+size">
      <input type="number" class="form-control" :name=name :id=uniq :value=modelValue :max=max :min=min :step=step  @change='$emit("update:modelValue", $event.target.value)' />
      <span class="help-block">{{error}}</span>
    </div>

    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>
  </div>
</template>

<script>

import _ from "underscore"

export default {
  name: "Number",
  
  data()
  {
    return { 
       uniq: null,
    }
  },
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    size: {
      type: Number,
      default: 6,
    },
    modelValue: {
       type: String,
    },
    labelSize: {
      type: Number,
      default: 3,
    },
    min: {
      type: [String,Number],
      default: null,
    },
    max: {
      type: [String,Number],
      default: null,
    },
    step: {
      type: [String,Number],
      default: null,
    },
    error: {
      type: String,
    },
    info: {
      type: String,
    }
    
  },
  
  mounted()
  {
    this.uniq = _.uniqueId("number")
  },
}
</script>
