define([
  'main',
  'vue',
  'common/visual/visual',
  'mount-vue-component', 
  'common',
], function(main, vue, Visual, mount, common)
{
  let state = vue.reactive({})

  return Object.create(Visual).extend(
  {
    create(el, opt)
    {
      if (!this.vue) throw "vue-module: No Vue module "
      Visual.create.apply(this, arguments)
      
      const app = main.getApp()
      
      if (!app.component(this.vue.default.name))
      {
        app.component(this.vue.default.name, this.vue.default)
      }

      this.$el.empty()
      const { vNode, destroy, el2 } = mount.mount(app.component(this.vue.default.name), 
        {props: {state}, element: this.$el[0], app})
    },
  
    setState(st)
    {
      common.deepAssign(state, st)
    },
    
  })    
})

