<template>
  <div class="container context-param">
    <div class="row well" v-for="(rec,i) in model">
      <table width="100%">
        <tbody>
        <tr>
          <td width="40%">
            <GenInput :size=9 :label-size=2 entity="nas-context" attr=context v-model="model[i].context" />
            <GenInput :size=9 :label-size=2 entity="nas-context" attr=cond v-model="model[i].cond" />
          </td>
          <td width="2%"></td>
          <td width="47%">
            <GenInput :rows=4 :size=10 :label-size=2 entity="nas-context" attr=rad_pairs v-model="model[i].rad_pairs" />
          </td>
          <td width="3%" align="right">
            <Icon name=trash @click=deleteContext(i) />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-11">&nbsp;</div>
      <div class="col-1"><Icon name=plus @click=addContext /></div>
    </div>
  </div>
</template>

<script>
import darsan from "darsan"
import GenInput from "common/visual/input/GenInput.vue"

export default {
  name: "ContextPairsNew",
  
  components: {GenInput},
  
  emits: [ 'update:modelValue' ],
  
  data()
  {
    return {
      model: [],
    }
  },
  
  methods: {
    deleteContext(i)
    {
      this.model.splice(i, 1)
      this.emit()
    },
    
    addContext()
    {
      this.model.push({context: "pppoe", rad_pairs: "", cond: null})
      this.emit()
    },
    
    emit()
    {
      this.$emit("update:modelValue", this.model)
    }
  },
  
}
</script>
