<template>
<div class="row">

  <div class="col-md-2" style="margin-bottom: 0px;">
    <SearchPanel :meta-api-topic=metaApiTopic :meta-api-path=metaApiPath :query=query @new-search=newSearch />
  </div>
  <div class="col-md-10">
    <div style="overflow-y: auto;">
      <Table ref=table :name=name :title=title :toolbar=toolbar :columns=columns :dynamic=dynamic :quick-search=quickSearch
       :syncURL=syncURL :fetch=fetch 
       :api-domain=apiDomain :api-topic=apiTopic :api-path=apiPath
       :row-class=rowClass :cell-class=cellClass
       :state=state 
       @load-query=loadQuery @toolbar-click=toolbarClick
      >
      // https://gist.github.com/loilo/73c55ed04917ecf5d682ec70a2a1b8e2
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData"><slot :name="name" v-bind="slotData" /></template>      
      </Table>
    </div>
  </div>
          
</div>
          
</template>

<script>

import SearchPanel from "common/search/SearchPanel.vue"

export default {

  name: "SearchTable",
  
  components: {SearchPanel},
  
  props: {
    // URL, по которому подтягивать описание полей поиска
    metaApiTopic: String,
    metaApiPath: String,
    
    // Все остальное для передачи в Table
    
      // Внутреннее имя таблицы, напр. для сохранения макета в настройках пользователя
      name: {
        type: String,
        required: true,
      },
      title: String,
      toolbar: {
        type: Array,
        default: () => []
      },
      columns: Array,
      // Добавляет редактирование макета: перенос колонок, изменение ширины, добавление и удаление
      dynamic: {
        type: Boolean,
        default: false,
      },
      // Добавляет в панель кнопок поле для быстрого поиска
      quickSearch: {
        type: String,
        default: null,
      },
      // Синхронизировать URL с состоянием компонента (?page=1&query=xxx&sort=-a)
      syncURL: {
        type: Boolean,
        default: true,
      },
      // Начальное состояние таблицы
      state: {
        type: String,
        default: "",
      },
      // Функция получения данных (если не подходит стандартная)
      fetch: Function,
      // Параметры доступа к данным (если используется функция получения по умолчанию)
      apiDomain: {
        type: String,
        default: "",
      },
      apiTopic: String,
      apiPath: String,
      // Раскраска строк
      rowClass: Function,
      // Раскраска ячеек
      cellClass: Function,
  },
  
  exposes: [
    "reloadTable",
  ],
  
  emits: [ "toolbar-click" ],
  
  data()
  {
    return {
      query: null,
    }
  },
  
  methods: {
    reloadTable()
    {
      this.$refs.table.reloadTable()
    },
    /////////////////////
    loadQuery(q)
    {
      this.query = q
    },
    
    newSearch(q)
    {
      this.$refs.table.setQuery(q)
    },
    
    toolbarClick(msg)
    {
      this.$emit("toolbar-click", msg)
    },
    
    currentQuery()
    {
      return this.$refs.table ? this.$refs.table.query : null
    }
  },
}
</script>
