<template>
<div>
  <Abon v-for="(a,i) in sortedQueue" :key=a.number :position="i+1" :seconds=a.seconds :caller=a.caller :number=a.number />
</div>
</template>

<script>
import Abon from "phone/page/Abon.vue"

export default {
  name: "AbonQueue",
  
  components: {Abon},
  
  props: {
    list: Array,
  },
  
  data()
  {
    return {
      timer: null,
      sortedQueue: [],
    }
  },

  mounted()
  {
    this.timer = setInterval(this.tick, 1000)
  },
  
  unmounted()
  {
    clearInterval(this.timer)
  },
  
  
  methods: {
    tick()
    {
      this.sortedQueue.forEach( rec => rec.seconds++ )
    }
  },
  
  computed: {
  },
  
  watch: {
    list: {
      immediate: true,
      handler(list)
      {
        var _list = {};
        list.map( value => { _list[value.id] = value })
        const newList = Object.values(_list).map( newrec =>
        {
          const oldrec = this.sortedQueue.find(el => el.caller==newrec.caller)
          const seconds = oldrec ? oldrec.seconds : +newrec.waited
          return { seconds, ...newrec }
        })
       
        this.sortedQueue = newList.sort((a,b) => a.seconds - b.seconds)
      }
    },
  },
  
}
</script>