<template>
  <select class="form-control" :multiple=multiple >
    <option v-if="withBlank" value=""></option>
    <option v-for="el in options" :key=el.value :value=el.value :selected="el.value == modelValue">{{el.text}}</option>
  </select>
</template>

<script>
  import $ from "jquery"

  export default {
    name: 'SimpleSelect2',

    data () {
      return {
        element: 'select',
      }
    },
    
    emits: [ 'update:modelValue' ],

    mounted() {
      const el = $(this.$el)
      el.select2({ allowClear: this.withBlank })
      el.select2('val', this.modelValue)

      el.on('change', ev => { this.$emit("update:modelValue", ev.val) })
    },

    props: {
      options: {
        type: Array,
      },

      multiple: Boolean,

      withBlank: {
        type: Boolean,
        default: false,
      },

      modelValue: {
        type: [ Number, String, Array ]
      },
    },

    watch: {
      modelValue(val) { $(this.$el).select2('val', this.modelValue) }
    },
  }
</script>
