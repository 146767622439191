import * as common from "common"
import moment from "moment"
import { getCurrentInstance } from 'vue'

export default {

  data()
  { 
    return {
      common,
      moment,
    }
  },
    
  methods: {
    ensure(...args)
    {
      return this.$store.dispatch("preloadEnsure", ...args)
    },
  },
  
  computed: {
    uniqueId()
    {
      return getCurrentInstance()?.uid
    },
  },
}
