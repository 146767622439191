<template>

  <SearchTable ref=table name="pppoe-log" title="История сессий PPPoE" :columns=columns dynamic 
   :apiDomain=domain apiTopic=client apiPath="/pppoe-log?paged=1"
   metaApiTopic=client metaApiPath="/meta/pppoe-log/search"
   :state=state.params
>
  <template #duration="{rec}">
    {{common.formatUptime(rec.duration)}}
  </template>

  <template #download="{rec}">
    {{common.formatHexSize(rec.input_octets64)}}
  </template>

  <template #upload="{rec}">
    {{common.formatHexSize(rec.output_octets64)}}
  </template>

  <template #uid="{rec}">
    <a :href="`/client/${rec.uid}`">{{rec.uid}}</a>
  </template>

  <template #nas="{rec}">
    <a :href="`/nas/${rec.nas}`">{{nasName(rec.nas)}}</a>
  </template>

  <template #mac="{rec}">
    <a data-direct-link="true" target="_blank" :href="`/device/switch?macClient=${rec.mac}`">
          {{rec.mac}}
    </a>
  </template>

  <template #ip="{rec}">
    <span :class="rec.error_pool ? 'text-danger' : 'text-success'"
      :title="common.formatErrorPoolDesc.text">
        <template v-if="common.formatErrorPoolDesc.ico" >
            <Icon :name="common.formatErrorPoolDesc.ico" />
        </template>
        {{rec.ip}}
    </span>
  </template>

  </SearchTable>
  
</template>

<script>
import StdPage from "common/visual/StdPage.vue"

export default {
    name: "PPPLogPage",
    
    mixins: [StdPage],
    
    props: {
      domain: {
        type: String,
        default: "",
      },
    },
    
    data()
    {
      return {
      
        columns: [
          {
            name: "start",
            title: "Старт",
          },
          {
            name: "end",
            title: "Конец",
          },
          {
            name: "duration",
            icon: "fa-clock-o",
            popup: "Длительность",
          },
          {
            name: "download",
            icon: "fa-download",
            popup: "Получено",
          },
          {
            name: "upload",
            icon: "fa-upload",
            popup: "Отправлено",
          },
          {
            name: "mac",
            title: "MAC",
          },
          {
            name: "nas",
            title: "NAS",
            sort: true,
          },
          {
            name: "ip",
            title: "IP",
          },
          {
            name: "uid",
            title: "UID",
            sort: true,
          },

        ],
        
      }
    },
    
    created()
    {
      this.ensure("nas")
   },
    
    methods: {
      nasName(id)
      {
        const rec = this.$store.state.preload[this.domain+"/nas"].find(el => el.entity==id)
        return rec ? rec.name : "?"
      }
    },
    
}
</script>
