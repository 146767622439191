<template>

<SearchTable ref=table name="tplan" title="Тарифные планы" :columns=columns dynamic 
 apiTopic=client apiPath="/tplan?paged=1" 
 metaApiTopic="client" metaApiPath="/meta/tplan/search"
 :toolbar=toolbar :state=state.params
 @toolbar-click=toolbarClick
>
  <template #type="{rec}">
    {{typeName(rec.type)}}
  </template>

  <template #per_day2="{rec}">
    {{common.fromMainCurrency(rec.per_day)}}
  </template>

  <template #per_month2="{rec}">
    {{common.fromMainCurrency(rec.per_month)}}
  </template>
  
  <template #name="{rec}">
    <a :href="'/tplan/'+rec.id">{{rec.name}}</a>
  </template>


</SearchTable>

</template>
  
<script>

import darsan from "darsan"
import SkelSearchTable from "common/visual/SkelSearchTable.vue"
import {goto} from "navigation"

export default {

    name: "TplanTable",
    
    components: {SkelSearchTable},
  
    props: {
      state: Object,
    },
    
    data()
    {
      return {
      
        toolbar: [
          {
            name: "add",
            icon: "plus",
            text: "Добавить",
          },
        ],
        
        columns: [
          {
            name: "id",
            title: "№",
            sort: true,
          },
          {
            name: "name",
            title: "Название",
            sort: true,
          },
          {
            name: "type",
            title: "Тип",
            sort: true,
          },
          {
            name: "speed",
            title: "Скорость",
            sort: true,
          },
          {
            name: "per_day",
            title: "В день",
            sort: true,
          },
          {
            name: "per_month",
            title: "В месяц",
            sort: true,
          },
          {
            name: "mandatory_withdrawal",
            title: "В минус?",
            sort: true,
          },
        ],
        
      }
    },
    
    created()
    {
      this.ensure(["tplan-types", "currency"])
    },
    
    activated()
    {
      if (this.$refs.table) this.$refs.table.reloadTable()
    },
    
    methods: {
      
      toolbarClick(msg)
      {
        if (msg.from=="add")
        {
          goto("/tplan/!new")
        }
      },
      
      typeName(type)
      {
        const rec = this.$store.state.preload['/tplan-types'].find(el => el.value==type)
        return rec ? rec.text : type
      },
      
    },
    
  }
</script>
