<template>
  <div>
    <h3>Поиск сессий</h3>

      <br/>

      <div class="well">
      <div class="form-inline">
        <div class="form-group">
          <label for="who-ip">IP-адрес:&nbsp;&nbsp;&nbsp;</label>
          <input type="text" class="form-control" id="who-ip" v-model="ip" />
        </div>
        <div class="form-group">
          <label for="who-date">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Дата и время:&nbsp;&nbsp;&nbsp;</label>
            <DatePicker :withTime=true class="form-control" id="who-date" v-model="date" />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button class="btn btn-primary" @click="search" :disabled="!(ip && date)" >Искать</button>
       </div>
       </div>

       <br/>

      <SimpleTable name="who-was" ref="table" title="Найденные сессии" :columns=columns :fetch=fetchRows >
         <template #uid="{rec}">
           <a :href="`/client/${rec.uid}`">{{rec.uid}}</a>
         </template>
         <template #start="{rec}">
           {{ rec.start | localTime }}
         </template>
         <template #end="{rec}">
           {{ rec.end | localTime }}
         </template>
      </SimpleTable>
  </div>
</template>

<script>

import DatePicker from "common/misc/DatePicker.vue"
import SimpleTable from "common/visual/SimpleTable.vue"
import {okPromise, formatLocalTime} from "common"
import darsan from "darsan"

export default {
  name: "WhoWas",
  
  components: {DatePicker, SimpleTable},
  
  data()
  {
    return {
    
      ip: "",
      date: "",
    
      columns: [
          {
            name: "uid",
            title: "UID",
          },
          {
            name: "type",
            title: "Сервис",
          },
          {
            name: "start",
            title: "Начало сессии",
          },
          {
            name: "end",
            title: "Конец сессии",
          },
      ],
    }
  },
  
  methods: {
  
    search()
    {
      this.$refs.table.reloadTable()
    },
    
    fetchRows()
    {
      if (!(this.ip && this.date)) return okPromise([])
      return darsan.get("", "client", "/srv/who-was", {ip: this.ip, time: this.date})
    },

  },
  
  filters: {
    localTime: formatLocalTime,
  },
}
</script>