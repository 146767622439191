<template>
    <table v-if="oldRow instanceof Object && !Array.isArray(oldRow)">
      <tr v-for="k in allKeysOf(oldRow, newRow)" >
        <td class="name">{{ humanName(k) }}</td>
        <ChangeRow :attr=attr :name=k :oldRow=oldRow[k] :newRow=newRow[k] />
      </tr>
    </table>
    <td colspan="2" v-else-if="isStrLike(name)"><AttrDiff :isLong=isLong(name) :oldStr="oldRow" :newStr="newRow" /></td>
    <template v-else>
      <td class="from">{{oldRow}}</td>
      <td>{{newRow}}</td>
    </template>
</template>  

<script>
import _ from "underscore"
import AttrDiff from "common/visual/history/AttrDiff.vue"

export default {

  name: "ChangeRow",
  
  components: {AttrDiff},
  
  props: {
    oldRow: null,
    newRow: null,
    name: {
      type: String,
      default: "",      
    },
    attr: {
      type: Function,
      default: n => { return {} },
    },
  },
  
  methods: {
    allKeysOf(rec1, rec2)
    {
      const keys = Object.keys(rec1).concat(Object.keys(rec2))
      const sorted = _.sortBy(keys, name => this.humanName(name))
      return _.unique(sorted, true)
    },
    
    isStrLike(name)
    {
      return ["string", "phone"].find(el => el == this.attr(name)?.type)
    },
  
    isLong(name)
    {
      const len = this.attr(name)?.length
      return len && len>100
    },
    
    humanName(name)
    {
      return this.attr(name)?.human || name
    },

  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
        
tbody tr:nth-child(even) {
  backdrop-filter: brightness(90%);
}

td.name {
  width: 10%;
  border-right: 1px solid lightgray;
  padding-left: 0;
  padding-right: 1em;
  font-weight: bold;
}

td {
  padding-left: 1em;
}
  
td.from {
  width: 45%;
  border-right: 1px solid lightgray;
}
</style>