<template>

<SearchTable ref=table name="client" title="Абоненты" :columns=columns dynamic 
 apiTopic=client apiPath="/client?paged=1" 
 metaApiTopic="client" metaApiPath="/meta/client/search" :rowClass=rowClass :cellClass=cellClass
 :toolbar=toolbar :state=state.params
 @toolbar-click=toolbarClick
>
  <template #Operations>
    <div class="gang bg-light" v-if="gangComp">
    <span class="close" @click="closeGang"><Icon name=fa-close /></span>
    <div class="alert alert-danger" style="width:98%">Внимание! Результат групповой операции нельзя будет отменить!</div>
    <keep-alive>
      <component :is="gangComp" :query=$refs.table.currentQuery() @dismiss=closeGang />
    </keep-alive>
    </div>
  </template>
  
  <template #online="{rec}">
    <template v-if="rec.online==1"><Icon name=star class="text-success"/></template>
    <template v-else>&nbsp;</template>
  </template>

  <template #entity="{rec}">
    <a :href="'/client/'+rec.entity">{{rec.entity}}</a>
  </template>
  
  <template #delete="{rec}">
    <Icon name=trash @click.native=deleteClient(rec.entity) />
  </template>

  <template #group="{rec}">
    {{rec.group}}: {{rec.group_name}}
  </template>

  <template #location_name="{rec}">
    {{rec.location_name}}<template v-if="rec.flat">, кв. {{rec.flat}}</template>
  </template>

  <template #phone="{rec}">
    {{rec.phone}}
    <template v-if="rec.phone2"><br/>{{rec.phone2}}</template>
    <template v-if="rec.phone3"><br/>{{rec.phone3}}</template>
  </template>

  <template #disabled="{rec}">
    <template v-if="rec.disabled==1"><Icon name=minus /></template>
    <template v-else>&#10003;</template>
  </template>

  <template #balance="{rec}" >
    {{common.formatCurrency2($store.state.preload["/currency"], rec.balance)}}
  </template>

  <template #deposit="{rec}" >
    {{common.formatCurrency2($store.state.preload["/currency"], rec.deposit)}}
  </template>

  <template #credit="{rec}" >
    {{common.formatCurrency2($store.state.preload["/currency"], rec.credit)}}
  </template>
          
</SearchTable>

</template>
  
<script>
import {notifyError} from "common"
import darsan from "darsan"
import SkelSearchTable from "common/visual/SkelSearchTable.vue"
import {goto} from "navigation"

const toolbar = [
          {
            name: "add",
            icon: "plus",
            text: "Добавить",
          },
]

const gangOp =  {
            name: "gang",
            icon: "fa-users",
            text: "Групповые операции",
            submenu: [
              {name: "gang-send-sms", text: "Отправить СМС"},
              {name: "gang-call-notify", text: "Oбзвон пользователей"},
              {name: "gang-change-ppp-tp", text: "Поменять тарифный план PPP"},
              {name: "gang-change-ppp2-tp", text: "Поменять тарифный план PPP+"},
              {name: "gang-change-ipoe-tp", text: "Поменять тарифный план IPoE"},
              {name: "gang-change-ipoe-device", text: "Поменять устройство IPoE"},
              {name: "gang-change-reduction", text: "Изменить скидку"},
              {name: "gang-change-status", text: "Включить/отключить абонента"},
              {name: "gang-change-group", text: "Изменить группу"},
              {name: "gang-change-fop", text: "Изменить ФОП"},
              {name: "gang-change-connection-type", text: "Изменить тип подключения"},
              {name: "gang-add-comment", text: "Добавить заметку"},
              {name: "gang-start-action", text: "Запустить акцию"},
              {name: "gang-mark-crash", text: "Проблемы с Интернетом"},
            ],
}

export default {

    name: "ClientTable",
    
    components: {SkelSearchTable},
  
    props: {
      state: Object,
    },
    
    data()
    {
      return {
      
        gangComp: null,
        
        columns: [
          {
            name: "online",
            title: "В сети",
            sort: true,
          },
          {
            name: "entity",
            title: "UID",
            sort: true,
          },
          {
            name: "login",
            title: "Логин",
            sort: true,
          },
          {
            name: "group",
            title: "Группа",
            sort: true,
          },
          {
            name: "fullname",
            title: "Ф.И.О.",
            sort: true,
          },
          {
            name: "location_name",
            title: "Адрес",
            sort: true,
          },
          {
            name: "phone",
            icon: "phone",
            sort: true,
          },
          {
            name: "disabled",
            title: "Откл",
            sort: true,
          },
          {
            name: "balance",
            title: "Баланс",
            sort: true,
          },
          {
            name: "deposit",
            title: "Депозит",
            sort: true,
          },
          {
            name: "credit",
            title: "Кредит",
            sort: true,
          },
          {
            name: "delete",
            title: "",
          },
        ],
        
      }
    },
    
    created()
    {
      this.ensure("currency")
    },
    
    methods: {
      
      rowClass(rec)
      {
        if (rec.disabled==1) return "warning"
      },
      
      cellClass(col, rec)
      {
        if (col.name=="balance" && rec.balance<0) return "danger"
      },
      
      deleteClient(uid)
      {
        if (confirm('Вы действительно хотите удалить абонента?'))
        {
          darsan.delete("", "client", "/client/"+uid)
          .done( () =>
          {
            this.$refs.table.reloadTable()
          })
        }
      },
      
      toolbarClick(msg)
      {
        if (msg.from=="add")
        {
          goto("/client/!new")
        }
        else if (msg.from.match(/^gang-/))
        {
          const compName = msg.from
          if (!this.$app.component(compName))
          {
            this.$app.component(compName, () => import("client/gang/" + msg.from + ".vue"))
          }
          if (this.$refs.table.currentQuery())
          {
            this.gangComp = this.$app.component(compName)
          }
          else
          {
            this.gangComp = null
            notifyError("Выбраны сразу все пользователи. Групповая операция будет слишком долгой")
          }
        }
      },
      
      closeGang()
      {
        this.gangComp = null
      },
      
    },
    
    computed: {
      toolbar()
      {
        const t = toolbar
        if (this.$store.getters.permGet("billing.gang"))
        {
          t.push(gangOp)
        }
        return t
      },
      
    },
    
    watch:
    {
      modus(val) { this.$refs.table.newSearch("") },
    },
    
  }
</script>

<style>
.gang
{
  border: 1px solid black;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0.5em;
}
</style>