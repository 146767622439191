define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common',
  'navigation',
  'sysconf',
  'preload',

  'common/table/tiny-table-view',
  'common/visual/visual',

  'text-loader!client/iptv/stat/layout.tpl',
  'text-loader!client/iptv/stat/row.tpl',
  'text-loader!client/iptv/stat/table.tpl'

], function ($, _, Backbone, darsan, common, navigation, sysconf, preload,
             TableView, visual, listTemplate, rowTemplate, tableTemplate) {

  return Object.create(visual).extend({

    name: "iptv",
    title: "Сессии IPTV",

    create: function (el, opt) {
      var me = this;

      visual.create.apply(me, arguments);
      me.renderFromTemplate(listTemplate, {});

      var SessionModel = Backbone.Model.extend({});

      var SessionList  = Backbone.CollectionEx.extend({
        model: SessionModel
      });

      me.collection = new SessionList();

      me.table = new TableView({
        el:            me.$el.find('div.table-responsive'),
        collection:    me.collection,
        rowTemplate:   rowTemplate,
        tableTemplate: tableTemplate,
        data: {  },
      });

      var toolbar = me.$el.find('.btn-toolbar.days');

      _.each(me._fetchParams, function (params, name) {
        toolbar.find(".btn." + name).on('click', function () {
          me._IPTV(params);
        });
      });
      
      return preload.ensure("iptv-channel")
      .done( () => 
      {
        me.iptvChannels = preload.get( config.domain, "iptv-channel" )
      });

    },

    setState: function (state) 
    {
      var me = this;

      if (me.state && me.state.uid == state.uid) return;
      me.state = state;

      prefix = sysconf.domainByUid(state.uid) || config.domain;

      this.collection.url = darsan.makeUrl(prefix, 'client', '/client/' + state.uid + '/iptv/session');
      me.fetch();
    },

    fetch: function(days=1) 
    {
      const me = this
      this.collection.fetch({ reset: true, data: {timespan: days*3600*24 } }).done(coll =>
      {
        coll.forEach(model => model.set("name", common.formatMapColl(me.iptvChannels, model.get("channel"), "no")))
        me.table.render()
      })
    },

  });
});