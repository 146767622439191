<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>

import TplanNew from "tplan/page/TplanNew.vue"
import TplanTable from "tplan/page/TplanTable.vue"
import TplanEditor from "tplan/page/TplanEditor.vue"

import { markRaw } from "vue"
import StdPage from "common/visual/StdPage.vue"

export default {
  name: "TplanPage",
  title: "Тарифные планы",
  mixins: [StdPage],
  
  data()
  {
    return {
      overlays: [
        {
          re: /\/tplan\/\d+/,
          component: markRaw(TplanEditor),
        },
        {
          re: /\/tplan\/!new/,
          component: markRaw(TplanNew),
        },
        {
          re: /(?:)/,
          component: markRaw(TplanTable),
        },
      ],
    }
  },
  
}

</script>
