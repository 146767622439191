<template>
      <ul class="nav navbar-nav">
        <template v-for="item in this.$store.state.menu">
        
          <template v-if="item.submenu && item.submenu.length">
            <li class="dropdown">
              <a :href="(item.url||'')" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"><span v-html=item.label /> <span class="caret"></span></a>
              <ul class="dropdown-menu" role="menu">
              
                <template v-for="(subitem,i) in item.submenu">
                  <template v-if="subitem == '---'">
                    <template v-if="i==0 || i==item.submenu.length-1 || !(item.submenu[i+1] instanceof Object)">
                    </template>
                    <li v-else class='divider' />
                  </template>
                  <li v-else>
                    <a :href=subitem.url :target="/^http/.test(subitem.url) ? '_blank' : '_self'" v-on:click="menuSubitemClick(subitem)">{{ subitem.label }}</a>
                  </li> 
                </template>
                
              </ul>
            </li>  
          </template>

        </template>
      </ul>
</template>

<script>

export default {
  name: "TopMenu",
  
  emits: [ "menuSubitemClick" ],
  
  created: function () {
    this.$store.dispatch("loadMenu")
  },

  methods: {
    menuSubitemClick (subitem) {
      this.$emit('menuSubitemClick', subitem)
    }
  },
}
</script>

<style>
</style>
