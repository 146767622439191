<template>
  <div v-if="ready">
      <h3 class="panel-title">Доступно каналов: <span class="total">{{total}}</span></h3>
      <table class="table table-bordered">
        <tbody>
          <tr v-for="p in channels.packages" :key="p">
            <td class="table-td">Пакет "{{package(p).display_name}}":</td>
            <td>
              {{package(p).channels.length}}
            </td>
          </tr>
          <tr style="background-color: #F0F0F0;" v-if="channels && channels.subscribed && channels.subscribed.length">
            <td class="table-td">Платных каналов:</td>
            <td>
              {{channels.subscribed.length}}
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</template>


<script>
import darsan from "darsan"
import {domainByUid} from "sysconf.js"

export default {
  name: "IptvChannels",
  
  props: {
    state: Object,
  },
  
  data() 
  {
    return {
      channels: {},
      ready: false,
      domain: null,
    }
  },
  
  created()
  {
    this.ensure(["iptv-package"])
      .then(() => this.ready = true)
  },
  
  methods: {
    async load()
    {
       this.domain = domainByUid(this.state.uid) || config.domain
       this.channels = await darsan.get(this.domain, "client", `/client/${this.state.uid}/iptv/accessible`)
    },
    
    package(no)
    {
      return this.$store.state.preload['/iptv-package'].find(el => el.entity==no)
    },
  },
  
  computed: {
    total()
    {
      if (!this.channels) return ""
      const subscribed = this.channels?.subscribed?.length || 0
      const packages = this.channels.packages || []
      return packages.reduce((acc, val) => acc+this.package(val).channels.length, subscribed)
    }
  },
  
  watch: {
    state: {
      immediate: true,
      handler(val)
      {
        this.load()
      },
    },
  },

}
</script>

<style scoped>
  .panel-title {
    margin-left: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  table {
    margin-bottom: 0;
  }

  .total {
    font-weight: bold;
    font-size: 1em;
  }

  .table-td {
    width: 30%;
  }
</style>
