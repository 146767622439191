<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>
  import LoopLogTable  from 'loop-log/page/LoopLogTable.vue'
  import StdPage from "common/visual/StdPage.vue"
  
  import { markRaw } from "vue"

  export default {
    name: 'LoopLogPage',
    title: 'Петли на устройствах',
    components: {LoopLogTable},
    mixins: [StdPage],
    
    data() 
    {
      return {
        overlays: [
        {
          re: /(?:)/,
          component: markRaw(LoopLogTable),
        }
      ]}
    },
    
  }
</script>
