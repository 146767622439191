<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>
import WorkerNew from "worker/page/WorkerNew.vue"
import WorkerTable from "worker/page/WorkerTable.vue"
import WorkerPanel from "worker/page/WorkerPanel.vue"

import StdPage from "common/visual/StdPage.vue"

import { markRaw } from "vue"

export default {
  name: "WorkerPage",
  title: "Работники",
  mixins: [StdPage],
  
  data()
  {
    return {
      overlays: [
        {
          re: /\/worker\/[\w$_]+/,
          component: markRaw(WorkerPanel),
        },
        {
          re: /\/worker\/!new/,
          component: markRaw(WorkerNew),
        },
        {
          re: /(?:)/,
          component: markRaw(WorkerTable),
        },
      ],
    }
  },
  
}

</script>
