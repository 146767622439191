<template>
  <Select :options=options :name=name :label=label :size=size :labelSize=labelSize :info=info :disabled=disabled
          :apiDomain=apiDomain :apiTopic=apiTopic :apiPath=apiPath :value=modelValue
          @update:modelValue=input />
</template>

<script>

import Select from "common/visual/cooper/Select.vue"

export default {
  name: "Boolean",
  components: {Select},
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    size: {
      type: Number,
      default: 6,
    },
    modelValue: {
       type: Boolean,
    },
    labelSize: {
      type: Number,
      default: 3,
    },
    apiDomain: {
      type: String,
      default: "",
    },
    apiTopic: {
      type: String,
    },
    apiPath: {
      type: String,
    },
    info: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  
  emits: [ 'update:modelValue' ],
  
  data() 
  {
    return {
      options: [
        {value: true, text: "Да"},
        {value: false, text: "Нет"},
      ],
    }
  },
  
  methods: {
    input(val)
    {
      this.$emit("update:modelValue", val=="true")
    }
  },
    
}
</script>
